<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> AI Presentation <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>

    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>AI Presentation Module - Quick start tutorial:</h4>
          </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2">
          <b-embed type="video" aspect="16by9" controls>
            <source src="https://speechormanager.univerhome.com/uploads/media/present-modu-tuto.mp4" type="video/mp4">
          </b-embed>
          
        </b-col>
      </b-row>

    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>AI Presentation Module - Details:</h4>
          <h6>What is the AI Presentation Module?</h6>
          <p>This module will help you generates your presentations in 5 seconds. The presentation generated is ready to be opened and presented with any slideshow app (Powerpoint, Google Slides,...etc).</p>

          <h6>How to use the AI Presentation Module?</h6>
          <p> 
             1) Select a language:<br>
             Select the language of your presentation from the list. The module support most of existing regular languages.<br><br>

             2) Write a title:<br>
             Write the presentation title, this title will show up in the first slide.<br><br>

             3) Select the presentation file format:<br>
             The module can generate two file formats: PPTX, and ODP. Both formats are the same and compatible with all existing slideshow apps such as Google slides, Powerpoint,...<br><br>

             4) Select the presentation background:<br>
             The module support 2 themes at the moment: a Black background with white text color, and a White background with Black text color. Remember that you can apply any advanced theme after opening the presentation file in your preferred slideshow app like Powerpoint, themes provided by Powerpoint could be applied with one click.<br><br>

             5) Write a description:<br>
             Describe the presentation subject, provide the most important main keywords and ideas you want to be included in the presentation, for example:<br>

             <code> Climate change dangers and solutions to save humanity.</code><br><br>

             Warning!! Don't do this:<br>
             <code>I want a presentation about: Climate change dangers and solutions to save humanity.</code><br><br>

             6) Select the number of slides:<br>
             Precise how much main slides you want to be in your presentaion. (Note: The title, table of content, Q&A and Thank you slides will be added automatically)<br><br>

             7) Generate the Presentation file:<br>
              Click "Generate" button and wait until it finishes, the file details and the download button will be shown in the table. Files are stored in our servers for 2 hours only, make sure to download the file locally in your device direcly after generation.<br><br>


             8) History table:<br>

              - In the history table you will find your last 2 hours generated presentations with the following details:<br>Title, Description, Slides, Credits cost ...
               <br><br>

              - You can copy the Title from the table to the Title Input-Text area: by clicking "Copy as Input" button.<br><br>

              - You can copy the Description from the table to the Description Input-Text area: by clicking "Copy as Input" button.<br><br>

              9) At the top of the User Interface you can see your available Credits and your lifetime used Credits. If you want to add credits you can purchase them by clicking "Boost" or click "Balance Boosters" under AI Presentation tab.
           </p>

           <h6>Presentation generation in the Speechor discord sever</h6>
          <p> 
             Join our <a href="https://discord.gg/GAXh9yMvJb">Speechor Discord server</a>, talk to Speechor Bot by executing /present command followed by the same in formation requested here, language, title ...., our Speechor Bot will reply with the AI generated presentation, also the presentation will be saved locally here under the Presentation UI tab. You can check more by visiting our <a href="https://discord.gg/GAXh9yMvJb">Speechor Discord server</a> community, where you can check other users presentations.
            
           </p>

           <h6>How to link my Discord account to my Speechor account?</h6>
           <p>First join our <a href="https://discord.gg/GAXh9yMvJb">Speechor Discord server</a>, talk to our Speechor Bot executing the command /subscribe, the bot will reply and DM you a secret code. Copy this code, go here in the settings tab, click Link/Change button under Discord Account section, Paste your code then submit. Once your account linked you can start executing commands requiring subsription like /present from our discord server.</p>
           
          </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
 
  data() {
    return {
    };
  },

};

</script>

<style scoped>
  code {
     font-family: Consolas,"courier new";
  color: crimson;
  background-color: #f1f1f1;
  padding: 2px;
  font-size: 105%;
  }

  h6 {
    color: orange;
  }
  P {
    color: white;
  }
</style>