<template>
  <b-container>
      <b-row class=" p-1" style="background-color:#2f2f30;">
        <div class="text-secondary"> Settings </div>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="4" class="p-4">
          <h4>Change password</h4>
          <b-alert v-if="passChanged[0]" variant="success"  show dismissible>{{ passChanged[1] }}</b-alert>
          <b-alert v-if="passError[0]" variant="danger"  show dismissible>{{ passError[1] }}</b-alert>
          <b-alert v-if="typeerr" variant="danger"  show dismissible>{{ typeerr }}</b-alert>
          <form @submit.prevent="onSubmitPass" data-vv-scope="pform">
            <!-- INPUTS -->
            <div class="input-group">
            <div>
              <p>Enter Old Password: </p>
              <input
              @input="reseterr" 
              v-validate="'required|min:10|max:16'"
              v-model = "oldpassword"
              name="old_password" 
              type="password" 
              placeholder="Old-Password" 
              autocomplete="off">
            </div>
            </div>  
            <div class="input-group mt-3">
              <div class="mr-2">
                <p>Enter New Password: </p>
                <input
                @input="reseterr"
                v-validate="'required|min:10|max:16'"
                v-model = "newpassword"
                name="new_password" 
                type="password" 
                placeholder="New Password" 
                ref="newpassword" 
                autocomplete="off">
              </div>
              <div>
                <p>Confirm New Password: </p>
                <input
                @input="reseterr"
                v-validate="'required|confirmed:newpassword'" 
                name="password_confirmation" 
                type="password" 
                placeholder="New Password, Again" 
                data-vv-as="password" 
                autocomplete="off"> 
              </div>  
           
            </div>

            <!-- ERRORS -->
            <div class="alert alert-danger" v-show="errors.any('pform')">
              <div v-if="errors.has('pform.new_password')">
                {{ errors.first('pform.new_password') }}
              </div>
              <div v-if="errors.has('pform.password_confirmation')">
                {{ errors.first('pform.password_confirmation') }}
              </div>
              <div v-if="errors.has('pform.old_password')">
                {{ errors.first('pform.old_password') }}
              </div>
            </div>

            <button class="submit_btn" type="submit" :disabled="errors.any('pform')">Submit Password</button>
          </form>

        </b-col>
        <b-col xs="12" md="4" class="p-4">
          <h4>Change email</h4>
          <b-alert v-if="emailChanged[0]" variant="success"  show dismissible>{{ emailChanged[1] }}</b-alert>
          <b-alert v-if="emailError[0]" variant="danger"  show dismissible>{{ emailError[1] }}</b-alert>
          <b-alert v-if="etypeerr" variant="danger"  show dismissible>{{ etypeerr }}</b-alert>
          <form @submit.prevent="onSubmitEmail" data-vv-scope="eform">
            <!-- INPUTS -->
            <div class="input-group">
            <div>
              <p>Enter Old Email: </p>
              <input
              @input="ereseterr" 
              v-validate="'required|email'"
              v-model = "oldemail"
              name="old_email" 
              type="email" 
              placeholder="Old-Email" 
              autocomplete="off">
            </div>
            </div>  
            <div class="input-group mt-3">
              <div class="mr-2">
                <p>Enter New Email: </p>
                <input
                @input="ereseterr"
                v-validate="'required|email'"
                v-model = "newemail"
                name="new_email" 
                type="email" 
                placeholder="New-Email"
                autocomplete="off">
              </div> 
            </div>

            <!-- ERRORS -->
            <div class="alert alert-danger" v-show="errors.any('eform')">
              <div  v-if="errors.has('eform.new_email')">
                {{ errors.first('eform.new_email') }}
              </div>
              <div v-if="errors.has('eform.old_email')">
                {{ errors.first('eform.old_email') }}
              </div>
            </div>
            <button class="submit_btn" type="submit" :disabled="errors.any('eform')">Submit Email</button>

          </form>

        </b-col>

        <b-col v-if="getpplemail[1]" xs="12" md="4" class="p-4">
          <h4>Affiliate Paypal email</h4>

          <p v-if="getpplemail[0]">Current address: {{ getpplemail[0] }}</p>

          <b-alert v-if="getpaypalCodeState[0]" :variant="getpaypalCodeState[2]"  show dismissible>{{ getpaypalCodeState[1] }}</b-alert>
          <b-alert v-if="ptypeerr" variant="danger"  show dismissible>{{ ptypeerr }}</b-alert>

          <button class="submit_btn" @click.prevent="sendCode" v-show="!getpaypalCodeSent">Add Paypal Email</button>

          <form v-show="getpaypalCodeSent" @submit.prevent="onSubmitPayEmail" data-vv-scope="payform">
            <!-- INPUTS -->
            <div class="input-group">
            <div>
              <p>Enter Paypal Email: (Your money will be sent to this email) </p>
              <input
              @input="preseterr" 
              v-validate="'required|email'"
              v-model = "payemail"
              name="paypal_email" 
              type="email" 
              placeholder="Paypal-Email" 
              autocomplete="off">
            </div>
            </div>  
            <div class="input-group mt-3">
              <div class="mr-2">
                <p>Enter code sent to your email: </p>
                <input
                @input="preseterr"
                v-validate="'required'"
                v-model = "code"
                name="code" 
                type="text" 
                placeholder="Code"
                autocomplete="off">
              </div> 
            </div>

            <!-- ERRORS -->
            <div class="alert alert-danger" v-show="errors.any('payform')">
              <div  v-if="errors.has('payform.code')">
                {{ errors.first('payform.code') }}
              </div>
              <div  v-if="errors.has('payform.paypal_email')">
                {{ errors.first('payform.paypal_email') }}
              </div>
            </div>
            <button class="submit_btn" type="submit" :disabled="errors.any('payform')">Submit Paypal Email</button>

          </form>

        </b-col>

        </b-row>


        <b-row class="justify-content-center">

          <b-col  xs="12" md="6" class="p-4">
          <h4 v-b-tooltip.bottom title="This is the list of your discord accounts, you can add/unlink accounts, with these accounts you can execute premium commands from any server where Speechor Bot is installed, you can install Speechor Bot in your own server, Speechor Bot tab for more details">Discord Accounts</h4>

          <acc-table></acc-table>

          <b-alert v-if="getdiscordCodeState[0]" :variant="getdiscordCodeState[2]"  show dismissible>{{ getdiscordCodeState[1] }}</b-alert>
          <b-alert v-if="dtypeerr" variant="danger"  show dismissible>{{ dtypeerr }}</b-alert>

          <button class="submit_btn" @click.prevent="sendDiscordCode('account')" v-show="!getdiscordCodeSent">Add new account</button>

          <form v-show="getdiscordCodeSent" @submit.prevent="onLinkDiscord('account', 'discform', hash, dcode)" data-vv-scope="discform">
            <!-- INPUTS -->
            <div class="input-group">
            <div>
              <p>Enter Discord code: (Obtained by /subscribe command in Disord Speechor server) </p>
              <input
              @input="dreseterr" 
              v-validate="'required'"
              v-model = "hash"
              name="hash"
              type="text"
              placeholder="Discord code" 
              autocomplete="off">
            </div>
            </div>  
            <div class="input-group mt-3">
              <div class="mr-2">
                <p>Enter code sent to your email: </p>
                <input
                @input="dreseterr"
                v-validate="'required'"
                v-model = "dcode"
                name="dcode" 
                type="text" 
                placeholder="Email verification code"
                autocomplete="off">
              </div> 
            </div>

            <!-- ERRORS -->
            <div class="alert alert-danger" v-show="errors.any('discform')">
              <div  v-if="errors.has('discform.dcode')">
                {{ errors.first('discform.dcode') }}
              </div>
              <div  v-if="errors.has('discform.hash')">
                {{ errors.first('discform.hash') }}
              </div>
            </div>
            <button class="submit_btn" type="submit" :disabled="errors.any('discform')">Link Discord Account</button>

            <button class="cancel_btn" @click.prevent="cancelDiscordLink">Cancel</button>

          </form>

        </b-col>
        
        <b-col  xs="12" md="6" class="p-4">
          <h4 v-b-tooltip.bottom title="This is the list of discord servers you own, you can add/unlink servers, you will get commissions for every server member payment on Speechor platform, check the Speechor Bot tab for more details">Discord Servers</h4>

          <srv-table></srv-table>

          <b-alert v-if="getsrvdiscordCodeState[0]" :variant="getsrvdiscordCodeState[2]"  show dismissible>{{ getsrvdiscordCodeState[1] }}</b-alert>
          <b-alert v-if="srvtypeerr" variant="danger"  show dismissible>{{ srvtypeerr }}</b-alert>

          <button class="submit_btn" @click.prevent="sendDiscordCode('server')" v-show="!getsrvdiscordCodeSent">Add new server</button>

          <form v-show="getsrvdiscordCodeSent" @submit.prevent="onLinkDiscord('server', 'srvform', srvhash, srccode)" data-vv-scope="srvform">
            <!-- INPUTS -->
            <div class="input-group">
            <div>
              <p>Enter Discord code: (Obtained by /admin command in Disord Speechor server) </p>
              <input
              @input="srvreseterr" 
              v-validate="'required'"
              v-model = "srvhash"
              name="srvhash"
              data-vv-as="Discord code" 
              type="text"
              placeholder="Discord code" 
              autocomplete="off">
            </div>
            </div>  
            <div class="input-group mt-3">
              <div class="mr-2">
                <p>Enter code sent to your email: </p>
                <input
                @input="srvreseterr"
                v-validate="'required'"
                v-model = "srccode"
                name="srccode"
                data-vv-as="verification code" 
                type="text" 
                placeholder="Email verification code"
                autocomplete="off">
              </div> 
            </div>

            <!-- ERRORS -->
            <div class="alert alert-danger" v-show="errors.any('srvform')">
              <div  v-if="errors.has('srvform.srccode')">
                {{ errors.first('srvform.srccode') }}
              </div>
              <div  v-if="errors.has('srvform.srvhash')">
                {{ errors.first('srvform.srvhash') }}
              </div>
            </div>
            <button class="submit_btn" type="submit" :disabled="errors.any('srvform')">Link Discord Server</button>

            <button class="cancel_btn" @click.prevent="cancelsrvDiscordLink">Cancel</button>

          </form>

        </b-col>

        

      </b-row>
  </b-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import accTable from "@/components/DiscordAccTable.vue";
  import srvTable from "@/components/DiscordServerTable.vue";
export default {
 
  data() {
    return {
      oldemail:'',
      payemail:'',
      newemail:'',
      ptypeerr:'',
      etypeerr:'',
      oldpassword:'',
      newpassword:'',
      typeerr:'',
      code:'',
      dcode:'',
      srccode:'',
      hash:'',
      srvhash:'',
      dtypeerr:'',
      srvtypeerr:'',

    };
  },

  components: { 
    'acc-table': accTable,
    'srv-table': srvTable
  },

  mounted(){
    this.$store.dispatch("getSettings", {router: this.$router});
  },
  
  computed: {
    ...mapGetters(["getpaypalCodeState","getpaypalCodeSent","getpplemail", "getdiscordCodeState", "getdiscordCodeSent", "getsrvdiscordCodeState", "getsrvdiscordCodeSent"]),

    passChanged(){
        var passchanged;
        if (this.$store.state.newpass[0] == true) 
        {
          passchanged = [true,this.$store.state.newpass[1]];
        }
        else
          {
            passchanged = [false,'']
          }
        return passchanged;
      },
    passError(){
        var passerror;
        if  (this.$store.state.failedpass[0] == true)
        {
          passerror = [true,this.$store.state.failedpass[1]];
        }
        else
          {
            passerror = [false,'']
          }
        return passerror;
      },

      emailChanged(){
        var emailchanged;
        if (this.$store.state.newemail[0] == true) 
        {
          emailchanged = [true,this.$store.state.newemail[1]];
        }
        else
          {
            emailchanged = [false,'']
          }
        return emailchanged;
      },
    emailError(){
        var emailerror;
        if  (this.$store.state.failedemail[0] == true)
        {
          emailerror = [true,this.$store.state.failedemail[1]];
        }
        else
          {
            emailerror = [false,'']
          }
        return emailerror;
      },
  },

  methods: {
    onSubmitPass () {
      this.$validator.validateAll('pform').then((result) => {
        if (result) {
          this.$store.dispatch("changePass", {oldpass: this.oldpassword, newpass: this.newpassword, router: this.$router});  
        } else {
          this.typeerr = 'Please fix these errors!';
        }
        
      });
      
    },

    onSubmitEmail () {
      this.$validator.validateAll('eform').then((result) => {
        if (result) {
          this.$store.dispatch("changeEmail", {oldemail: this.oldemail, newemail: this.newemail, router: this.$router});  
        } else {
          this.etypeerr = 'Please fix these errors!';
        }
        
      });
      
    },

    sendCode(){
      this.$store.dispatch("codePayEmail", {router: this.$router});
    },

    sendDiscordCode(item){
      item == 'server' ? this.cancelDiscordLink() : this.cancelsrvDiscordLink();
      this.$store.dispatch("codeDiscordLink", {router: this.$router, item:item});
    },

    onSubmitPayEmail () {
      this.$validator.validateAll('payform').then((result) => {
        if (result) {
          this.$store.dispatch("changePayEmail", {payemail: this.payemail, code: this.code, router: this.$router});  
        } else {
          this.ptypeerr = 'Please fix these errors!';
        }
        
      });
      
    },

    onLinkDiscord (item, form, discordHash, verifCode) {
      this.$validator.validateAll(form).then((result) => {
        if (result) {
          this.$store.dispatch("changeDiscordLink", {hash: discordHash, code: verifCode, item: item, router: this.$router});  
        } else {
          if(item == 'account'){this.dtypeerr = 'Please fix these errors!';}
          else{this.srvtypeerr = 'Please fix these errors!';}
          
        }
        
      });
      
    },
    showUnlinkDilog(){
        this.$bvModal.show('unlinkConfirm');
    },

    cancelDiscordLink(){
      this.dtypeerr = '';
      this.$store.dispatch("cancelDiscordLink");
    },
    cancelsrvDiscordLink(){
      this.srvtypeerr = '';
      this.$store.dispatch("cancelsrvDiscordLink");
    },

    reseterr() {
      this.typeerr = '';
    },

    ereseterr() {
      this.etypeerr = '';
    },

    preseterr() {
      this.ptypeerr = '';
    },
    dreseterr() {
      this.dtypeerr = '';
    },
    srvreseterr() {
      this.srvtypeerr = '';
    }
  }

};

</script>

<style scoped>
  .submit_btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    margin-top: 8px;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    }
  .cancel_btn {
    background-color: #696969;
    border: none;
    color: white;
    margin-top: 16px;
    margin-left: 5px;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    }
</style>