<template>
  <div>
    
    <b-sidebar 
    id="sidebar-1"  
    title="Sidebar"
    bg-variant="dark" 
    width="180px" 
    @shown="addLeftPadding" 
    @hidden="cancelLeftPadding" 
    no-header 
    no-slide
    no-close-on-route-change
    >
      <b-navbar-brand class="px-3 py-3 m-0 alignbrand w-100" href="#"><img src="../assets/logo.png" class="d-inline-block align-center" width="125" height="50" alt="Speechor"></b-navbar-brand>

      <template>
        <b-navbar v-show="sho" toggleable type="dark" variant="dark">
          <b-navbar-brand tag="div" class="nav-txt" to="/Imagine">AI Image <b-badge variant="danger">new</b-badge></b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-img" class="tog-small">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="chevron-bar-up" font-scale="0.7" ></b-icon>
              <b-icon v-else icon="chevron-bar-down" font-scale="0.7"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-img" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/Imagine">Image UI</b-nav-item>
              <b-nav-item to="/ImagineBoost">Image Boosters</b-nav-item>
              <b-nav-item to="/ImagineTutorial">Image Tutorial</b-nav-item>
              
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </template>
      
      <template>
        <b-navbar toggleable type="dark" variant="dark">
          <b-navbar-brand tag="div" class="nav-txt" to="/Speechor">Text to Speech</b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-Speechor" class="tog-small" style="padding: 2px 4px;">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="chevron-bar-up" font-scale="0.7" ></b-icon>
              <b-icon v-else icon="chevron-bar-down" font-scale="0.7"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-Speechor" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/Speechor">Text to Speech UI</b-nav-item>
              <b-nav-item to="/MusicLibrary">Music Library</b-nav-item>
              <b-nav-item to="/Tutorial">TTSpeech Tutorial</b-nav-item>
              <b-nav-item to="/AddFunds" v-show="getBalance[1] !== '0' || getBoost">Characters Boosters</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </template>
      
      <template>
        <b-navbar v-show="sho" toggleable type="dark" variant="dark">
          <b-navbar-brand tag="div" class="nav-txt" to="/Presentor">AI Presentation</b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-Dalle2" class="tog-small">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="chevron-bar-up" font-scale="0.7" ></b-icon>
              <b-icon v-else icon="chevron-bar-down" font-scale="0.7"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-Dalle2" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/Presentor">Presentation UI</b-nav-item>
              <b-nav-item to="/PresBoost">Presentation Boosters</b-nav-item>
              <b-nav-item to="/PresTutorial">Presentation Tutorial</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </template>


      <template>
        <b-navbar toggleable type="dark" variant="dark">
          <b-navbar-brand tag="div" class="nav-txt" to="/AIgenerator">AI Text</b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-ChatGPT" class="tog-small">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="chevron-bar-up" font-scale="0.7"></b-icon>
              <b-icon v-else icon="chevron-bar-down" font-scale="0.7"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-ChatGPT" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/AIgenerator">AI Text UI</b-nav-item>
              <b-nav-item to="/creditsbooster">AI Text Boosters</b-nav-item>
              <b-nav-item to="/AItexttutorial">AI Text Tutorial</b-nav-item>
              
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </template>
      
      <template>
        <b-navbar toggleable type="dark" variant="dark">
          <b-navbar-brand tag="div" class="nav-txt" to="/Affiliate-Dashboard">Affiliate Program</b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-affiliate" class="tog-small">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="chevron-bar-up" font-scale="0.7" ></b-icon>
              <b-icon v-else icon="chevron-bar-down" font-scale="0.7"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-affiliate" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/Affiliate-Dashboard">Affiliate Dashboard</b-nav-item>
              <b-nav-item to="/Affiliate-Tutorial">Affiliate Guide</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </template>
      <div>
        <ul>
          <router-link class="" tag="li" to="/SpeechorBot">Speechor Bot <b-badge variant="danger">new</b-badge></router-link>
          <router-link class="" tag="li" to="/Settings">Account Settings</router-link>
        </ul>
      </div>
    </b-sidebar>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      sho:true
    };
  },
  computed: {
    ...mapGetters(["getBalance", "getBoost"])
  },

  mounted(){
    var swidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if (swidth>560) {
      let sidBardisplay= document.getElementById("sidebar-1");
      sidBardisplay.style.display ='';
      sidBardisplay.removeAttribute("aria-hidden");
      document.getElementById("app").style.paddingLeft = "180px";
      document.getElementById("footer-stl").style.paddingLeft = "180px";
    }
     
  },
  methods:{
    addLeftPadding(){
      document.getElementById("app").style.paddingLeft = "180px";
      document.getElementById("footer-stl").style.paddingLeft = "180px";
    },
    cancelLeftPadding(){
      document.getElementById("app").style.paddingLeft = "0px";
      document.getElementById("footer-stl").style.paddingLeft = "0px";
    }
  }
};

</script>

<style scoped>
  .alignbrand{
    text-align: center;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #343a40;

  }
  li{
    border-bottom: 1px;
    display: block;
    min-height: 30px;
    line-height: 30px;
    color: #f1f1f1;
    padding: 8px 16px;
    text-decoration: none;
  }

  li:hover{
    background-color: #555;
    color: white;
    cursor: pointer;
  }

  li.router-link-exact-active {
    background-color: #272733;
    color: white;
  }

  a.navbar-brand {
    color: #f1f1f1;
  }

  /* Style The Dropdown Button */

  li.nav-item {
    padding: 0px 8px;
  }
  .nav-txt {
    font-size: 12px;
  }
  
  .tog-small {
    border: none;
    padding: 2px 4px;
  }

    /* Style The Dropdown Button */
  .dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }

 
</style>