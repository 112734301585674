<template>
  <b-container fluid>
  	<b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> AI Presentation <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>
  	<b-row class="justify-content-center">
  		<p class="text-secondary text-center pt-2">From here you can boost the "AI Presentation Module" Balance. <br>Credits will be added instantly after purchase.</p>
  	</b-row>
  	<b-row class="mb-3">
      <b-col v-bind:key='product.Product' v-for="product in getPresProducts" xs="12" md="4" class="p-2">
		    <b-card
		    
				no-body
				style="max-width: 20rem; background-color: #7930ff;"
				
				>
			    <template #header>
			      <h6 class="mb-0 text-center">{{product.Product}}</h6>
			      <h4 class="m-2 text-center">${{product.Price}}</h4>
			    </template>
			    <b-list-group flush>
			      	<b-list-group-item variant="dark">
				      	<p class="mb-0 text-center">Basic Offer</p>
				      	<h6 class="mb-0 text-center">{{product.Basic}}</h6>
			  		</b-list-group-item>
			        <b-list-group-item variant="dark">
			      		<p class="mb-0 text-center">Free Gift</p>
				      	<h6 class="mb-0 text-center">{{product.Bonus}}</h6>
				    </b-list-group-item>
			        <b-list-group-item variant="dark">
			           <P class="mb-0 text-center">Total Offer</P>
				      	<h6 class="mb-0 text-center">{{product.Total}}</h6>
				    </b-list-group-item>
			    </b-list-group>
			    <b-card-body>
			        <b-button 
			        v-if="getPresProducts.indexOf(product)==1"
					    class="w-100 mt-2" 
					    size="col-md-4"				    
					    squared 
					    :id="'boost-'+getPresProducts.indexOf(product)" 
					    variant="warning"
					    @click.prevent="boostNow(product.Product)"
					    >Boost Now</b-button>

					    <b-button 
					    v-else
					    class="w-100 mt-2" 
					    size="sm"				    
					    squared 
					    :id="'boost-'+getPresProducts.indexOf(product)" 
					    variant="primary"
					    @click.prevent="boostNow(product.Product)"
					    >Boost Now</b-button>
			    </b-card-body>
			</b-card>
	    </b-col>	    
	</b-row>
  </b-container>
</template>

<script>
	import { mapGetters } from "vuex";
export default {
	data() {
	    return {
	    };
	},
    computed: {
      ...mapGetters(["getPresProducts"]),
    },
    
    mounted() {
    	this.$store.dispatch("getProducts", {router: this.$router});
      
       
    },

    methods:{
    	boostNow(item){
    		//var item = "Micro Booster";
    		this.$store.dispatch("payment", {product: item, router: this.$router});
    	}
    }
};
</script>