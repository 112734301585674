<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> Text to Speech <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>
    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>Text to Speech Module - Quick start tutorial:</h4>
          </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2">
          <b-embed type="video" aspect="16by9" controls>
            <source src="https://speechormanager.univerhome.com/uploads/media/Texttospeech-modu-tuto-1c.mp4" type="video/mp4">
          </b-embed>
          
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>Important recommendations:</h4>
          <p><span style="color:green;font-weight:bold">&#10004;</span> Always use a correct and readable text, respecting the grammar of each language.</p>
          <p><span style="color:green;font-weight:bold">&#10004;</span> The AI technology is sensitive to punctuation marks (e.g: comma( , ), full stop( . ), question mark( ? ), exclamation mark( ! ), colon( : ), ...) so, a good positioning of them will improve the audio quality.</p>
          <p><span style="color:green;font-weight:bold">&#10004;</span> Arabic text needs to be improved by adding Vowels (e.g: Damma, Fatha, Sukūn ...) to get a correct reading.</p>
          <p><span style="color:green;font-weight:bold">&#10004;</span> All generated voices will sound natural without using any custom settings. If you customize a voice you will influence the quality.</p>
          <p><span style="color:green;font-weight:bold">&#10004;</span> We recommend using Audacity (https://www.audacityteam.org), if you want to murge files or add musics to audios.</p>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
 
  data() {
    return {
    };
  },

};

</script>

<style scoped>
  P {
    color: white;
  }
</style>