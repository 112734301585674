<template>
    <b-row no-gutters>
    	<b-col v-if="getBalance[1] == 0" xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="success" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">Musics</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="file-music"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="h6" class="text-success numbers">80+</b-card-text>
	      	<b-card-text text-tag="p">Available Musics</b-card-text>
	      </b-card>
	    </b-col>
	    <b-col v-else xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="success" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">BOOSTED</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bar-chart-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="h6" class="text-success numbers">{{ getBalance[1] }}</b-card-text>
	      	<b-card-text text-tag="p">Available boosted (<b-link class="" tag="li" to="/AddFunds"> Boost</b-link> )</b-card-text>
	      </b-card>
	    </b-col>
	    
	    <b-col xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="danger" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">USED</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="card-text"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="h6" class="text-danger numbers">{{ getBalance[0] }}</b-card-text>
	      	<b-card-text text-tag="p">Used characters</b-card-text>
	      </b-card>
	    </b-col>
      	<b-col xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="primary" text-variant="secondary" header-tag="header" class="bg-transparent text-center">
	      	<template #header>
	      		<div class="row">
	      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">ACTORS</p>
	      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="person-check-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="h6" class="text-primary">{{getTotalActors}}</b-card-text>
	      	<b-card-text text-tag="p">Available actors</b-card-text>
	      </b-card>
	    </b-col> 
	    <b-col xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="info" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">LANGUAGES</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="chat-left-dots"  font-scale="2" aria-hidden="true"></b-icon>
	      	    </div>
	      	</template>
	      	<b-card-text text-tag="h6" class="text-info">{{ getLangOptions.length }}</b-card-text>
	      	<b-card-text text-tag="p">Supported languages</b-card-text>
	      </b-card>
	    </b-col>
	    
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["getTotalActors","getBalance","getLangOptions"])
  },
  mounted() {
      this.$store.dispatch("getActorsCount", {router: this.$router});
  }

};

</script>

<style scoped>
  .widget-css {
  	background: #ddff87;
  	border-radius: 3px;
  }

  .icon-color {
  	color: #BFC9CA;
  	opacity: 0.1;
  }
  header.card-header {
  	padding: 4px;
  }

  div.card-body {
  	padding: 8px;
  }
</style>