import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import VeeValidate from 'vee-validate';
import "./plugins/bootstrap-vue";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields'
});
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
