<template>
  <div class="w-100 p-3"> 
    <b-row class="d-flex justify-content-around my-1">
      <b-col sm="12" md="6" class="pl-0 pr-0">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-col>
    
      <b-col sm="12" md="6" class="pl-0 pr-0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <!-- Main table element -->
      <b-table
        class="text-light"
        show-empty
        striped
        dark
        small
        stacked="md"
        ref="tableRef"
        :items="getsrvDiscord"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
      <template #cell(serverId)="row">
          <div class="w-100" v-html="row.value"></div>
        </template>
      <template #cell(actions)="row">
           <b-button size="sm" v-b-tooltip.bottom title="Unlink Server" variant="secondary" @click.prevent="showUnlinkDilog(row.item)" class="mr-1 mb-1">Unlink</b-button>
        </template>
      </b-table>
      <b-modal
          id="unlinksrvConfirm"
          title="Unlink Discord Server"
          @ok="unlink()"
          ok-title="Unlink"
          size="sm"
          button-size="sm"
          no-close-on-esc 
          no-close-on-backdrop >
            <div style="text-align: center;">
                  <p>You are unlinking the current discord account, Do You Confirm?</p>
                  <b-icon icon="exclamation-triangle" font-scale="2" animation="throb" variant="danger"></b-icon>
            </div>
        </b-modal>
  </b-row>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
       
    },
    data() {
      return {
        fields: [
          { key: 'serverId', label: 'Account ID'},
          { key: 'serverName', label: 'User Name'},
          { key: 'actions', label: 'Actions'},
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        userId:'' ,
        srvIdToUnlink:'',
      }
    },
    computed: {
       ...mapGetters(["getsrvDiscord"]),

    },

    mounted() {
          
    },
    
    updated(){
      this.totalRows = this.$store.state.accrecords.length;
      this.userId = this.$store.state.currentUId;
    },

    methods: {   
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      unlink(){
        this.$store.dispatch("unlinkDiscord", { itemId:this.srvIdToUnlink, type:'server', router: this.$router });
      },

      showUnlinkDilog(item){
        this.srvIdToUnlink = item.serverId;
        this.$bvModal.show('unlinksrvConfirm');
    },
    }
  }

</script>

<style scoped>
  /*.resize-tb{
    width: 1200px;
  }*/
</style>