<template>
    <div class='w-100 px-3'>
        <b-row class=" p-1" style="background-color:#2f2f30;">
          <div class="text-secondary"> Text to Speech <span class="text-warning">&#8250;</span> {{$route.name}}</div>
        </b-row>   
        <!-- Table -->
        <b-row class="">
          <music-table></music-table>
        <!-- /Table -->
        </b-row> 
    </div>

</template>

<script>
import MusicTable from "@/components/MusicTable.vue";
export default {
 
  data() {
    return {
      
    };
  },

  components: { 
  
    'music-table': MusicTable 
    
  },


  computed: {

  }

};

</script>

<style scoped>
  
</style>