<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">
        <div class="text-secondary"> AI Text <span class="text-warning">&#8250;</span> {{$route.name}}</div>
      </b-row>

      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>AI Text Module - Quick start tutorial:</h4>
          </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2">
          <b-embed type="video" aspect="16by9" controls>
            <source src="https://speechormanager.univerhome.com/uploads/media/aitext-modu-tuto.mp4" type="video/mp4">
          </b-embed>
          
        </b-col>
      </b-row>
    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>AI Text Module - Details:</h4>
          <h6>What is the AI Text Module?</h6>
          <p>The AI Text module can generate text content from your text input. The tool is based on the latest artificial intelegence technology, it provides high quality result. The more your input is clear the more the output will be well refined.</p>

          <h6>How to use AI Text module?</h6>
          <p> 
             1) Enter your text input:<br>
             Go to the Input-Text area, and Write your text input as clear as possible to get what you want from the tool.<br><br>

             2) Select the Max credits limit:<br>
             With the slider under the input text. Select the Max Credits allowed to be used by the tool to generate the text, the Generator will not exceed this limit. <br>
             (1 Credit = 4 characters of text)<br><br>
             3) Generate the output:<br>
              Click "Generate" button and wait until the it finishes, the result will be shown in the Output-Text area, highlighted with  green background. Also generation details are saved in the history table (Last 10 days only).<br><br>
             4) History table:<br>

              - In the history table you will find your last 10 days generations with the following details:<br>
              Input: text you used to generate the output.<br>
              Output: AI text generated by the AI text generator tool.<br>
              Input_Credits: Credits of the input text.<br>
              Output_Credits: Credits of the output text.<br>
              All_Credits: Total of Credits = Input_Credits + Output_Credits<br>
               <br><br>

              - You can copy text from table to In the Input-Text area: by clicking "Copy to Input-Text" button. if you want to reuse the text again to get another result.<br><br>

              - You can copy text from the table to the Text to Speech tool: by clicking "Text to Speech" button. If you want to generate an audio file from this text.<br>

              5) At the top of the tool panel you can see your available Credits and your lifetime used Credits. If you want to add credits you can purchase them by clicking "Boost" or click "Balance Boosters" under AI Text tab.
           </p>
           
          <h6>Write clear instructions:</h6>
          <p>
            AI Text module can’t read your mind. If the output is too long, ask for brief reply. If the output is too simple, ask for expert-level writing. If you dislike the format, demonstrate the format you’d like to see. The less AI Text module have to guess at what you want, the more likely you’ll be to get it.
          </p>

          <h6>Provide any important details:</h6>

          <p>In order to get a highly relevant output, make sure that you provide any important details or context. Otherwise you are leaving it up to the AI Text module to guess what you mean.<br> 
          for example: <br>
          This is a worse input: <br>
          <code>Write an email</code> <br>
          And this is a better one: <br>
          <code>Write a persuasive email to convince potential customers to try our service</code></p>
          
          <h6>Define the length and the form of the output:</h6>

          <p>
            You can provide more details about the length and the form of the output 
          for example: <br>
          <code>Summarize the text delimited by triple quotes in about 50 words.<br>
          """insert text here""" </code><br><br>
          Or:<br>
          <code>Summarize the text delimited by triple quotes in 2 paragraphs.<br>
          """insert text here"""</code><br><br>
          Or:<br>
          <code>Summarize the text delimited by triple quotes in 3 bullet points.<br>
          """insert text here"""</code>
          </p>
          </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
 
  data() {
    return {
    };
  },

};

</script>

<style scoped>
  code {
     font-family: Consolas,"courier new";
  color: crimson;
  background-color: #f1f1f1;
  padding: 2px;
  font-size: 105%;
  }

  h6 {
    color: orange;
  }
  P {
    color: white;
  }
</style>