//(authentication module)

import AuthService from '../services/auth.service';

const blueSky = JSON.parse(localStorage.getItem('blueSky'));
const initialState = blueSky
  ? { status: { loggedIn: true }, blueSky }
  : { status: { loggedIn: false }, blueSky: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, {blueSky, retoken}) {
      return AuthService.login(blueSky, retoken).then(
        response => {
          if (response.response.status == '200'){
            commit('loginSuccess', response);
            return Promise.resolve(response);
          } else {
            commit('loginFailure');
            return Promise.reject(response);
          }
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    }
  },
  mutations: {
    loginSuccess(state, blueSky) {
      state.status.loggedIn = true;
      state.blueSky = blueSky;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.blueSky = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.blueSky = null;
    }
  }
};