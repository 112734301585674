<template>
 <div class="custbackgrd">
    <b-navbar id="topheader" toggleable="md" type="dark" variant="transparent">
        <b-navbar-brand  href="https://speechor.com">
          <img src="../assets/logo.png"   height="75" alt="Speechor">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item class = "txt-head" href="https://speechor.com">Home</b-nav-item>
            <b-nav-item class = "txt-head" href="https://speechor.com/Askus">Support</b-nav-item>

          </b-navbar-nav>

        </b-collapse>
      </b-navbar>
      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8"  class="p-3">
          <b-card class="bg-dark text-center" title="Reset Password">
            <b-card-text v-show="formshow">
              Enter your user email correctly then click send, you will receive the password reset instructions, if you can not remember your email contact us.
            </b-card-text>
            <b-card-text v-show="!formshow">
              Enter the code sent to your email (it could take 5mins), check the spam folder too. If it's not received yet try to send it again or contact us.
            </b-card-text>
            <b-alert v-if="message[0]" :variant="message[2]"  show>{{message[1]}}</b-alert>
            <b-alert v-if="getSendCodeState[0]" :variant="getSendCodeState[2]"  show>{{getSendCodeState[1]}}</b-alert>
            <b-form data-vv-scope="sendform" v-show="formshow">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                placeholder="Email"
                v-model="email"
                v-validate="'required|email'"
                type="email"
                data-vv-as="email"
                name="email"
                /> 
              </b-form-group>


              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('sendform')">
                <div v-if="errors.has('sendform.email')">
                  {{ errors.first('sendform.email') }}
                </div>
                <div v-if="errors.has('sendform.password_confirmation')">
                  {{ errors.first('sendform.password_confirmation') }}
                </div>
              </div>
             
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="loading"
                @click.prevent="sendEmailPassCode"
                >
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Send Email</span>
                </b-button>
              </b-form-group>
            </b-form>

            <b-form data-vv-scope="submitcode" v-show="!formshow">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                placeholder="Enter verification code"
                v-model="code"
                v-validate="'required'"
                type="text"
                data-vv-as="code"
                name="code"
                /> 
              </b-form-group>


              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('submitcode')">
                <div v-if="errors.has('submitcode.code')">
                  {{ errors.first('submitcode.code') }}
                </div>
              </div>
             
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="submitloading"
                @click.prevent="verifyPassCode"
                >
                  <span v-show="submitloading" class="spinner-border spinner-border-sm"></span>
                  <span>Submit Code</span>
                </b-button>
              </b-form-group>
              
              </b-form>
              <b-button 
                size='sm'
                variant="link"
                class="btn-block"
                ><a href="https://speechor.com/Askus" target="_self">Contact us</a>
              </b-button>
              </b-card>
      </b-col>
    </b-row>
    <div class="spacing-div" style="height: 70px; width: 100%;"></div>
    <footer id="footer-stl" class="bg-dark">
      <b-navbar type="dark" class="nav-stl" > 
        <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
          <b-nav-item  href="https://speechor.com/">Home</b-nav-item>
          <b-nav-item href="https://speechor.com/Askus">Support</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <b-navbar type="dark" class="nav-stl" >
        <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
          <b-nav-item href="https://speechor.com/Privacypolicy">Privacy</b-nav-item>
          <b-nav-item href="https://speechor.com/Cookies">Cookies</b-nav-item>
          <b-nav-item href="https://speechor.com/Terms">Terms</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    <p class="mb-0 p-1 text-secondary text-center txt-foot-b"> &copy; <span>{{year}}</span> by Speechor</p>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      //formshow:true,
      email: '',
      loading: false,
      submitloading: false,
      message: [false,'',''],
      year:'2024',
      code:''
    };
  },
  mounted(){
    this.$store.dispatch("initialize");
    this.year = new Date().getFullYear();
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    ...mapGetters(["getSendCodeState"]),

    formshow(){
        return this.$store.state.codeformshow;     
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    /* eslint-disable */
    sendEmailPassCode() {
      this.loading = true;
      this.$validator.validateAll('sendform').then(

      (isValid) => {
        if (isValid) {
          grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute('6LcLCS4qAAAAAB8svEDye5p_D-2ZEdo1-11y1kRO', {action: 'SENDEMAILCODE'});
            await this.$store.dispatch('sendEmailPassCode', {cpath:"forgotPassword", cprefix:"_FPSS", email:this.email, retoken: token, router: this.$router});
            this.loading = false;
          });    
        } 
        else {
          this.message = [true,'Please fix these errors!', 'danger'];
          this.loading = false;
        }
      }, 
      (error) => {
              this.loading = false;
              this.message = [true, error, 'danger'];
      });
    },

    verifyPassCode() {
      this.submitloading = true;
      this.$validator.validateAll('submitcode').then(

      (isValid) => {
        if (isValid) {
          grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute('6LcLCS4qAAAAAB8svEDye5p_D-2ZEdo1-11y1kRO', {action: 'VERIFYEMAILCODE'});
            await this.$store.dispatch('verifyPassCode', {cpath:"forgotPassword", cprefix:"_FPSS", reason: 'passreset', code: this.code, email:this.email, retoken: token, router: this.$router});
            this.submitloading = false;
          });
        } else {
          this.message = [true, 'Please fix these errors!', 'danger'];
          this.submitloading = false;
        }
      }, 
      (error) => {
              this.submitloading = false;
              this.message = [true, error, 'danger'];
      });
    },
    /* eslint-enable */

    reseterr() {
      this.message = [false,'',''];
    },
  
  },
};
</script>

<style scoped>

#nav a.router-link-exact-active {
  color: white;
}

@media not screen and (max-width: 767px) {
  #topheader{
    padding-right: 5%;
    padding-left: 5%;
  }
}

#nav-brand{
  margin-left: 30px;
}

.txt-head{
  display: block;
  font-size: 1em;
  margin-top: 0.45em;
  margin-bottom: 0.45em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 200;
}

#footer-stl{ 
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .nav-stl{
    background-color:#31004b!important;
  }

  .txt-foot-b{
  font-size: 0.8em;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 100;

  }

.custbackgrd {
  background: rgb(110,0,183);
  background: linear-gradient(180deg, rgba(75, 0, 130, 1)0%, rgba(114, 14, 158, 1) 20%, rgba(128, 0, 128, 1)40%,rgba(69, 44, 99, 1)60%,rgba(51, 0, 111, 1)80%);
  margin-left: 0px;
  margin-right: 0px;
  min-height: 100vh;
}
.profile-img-card {
  width: 75px;
  height: 75px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 25%;
  -webkit-border-radius: 25%;
  border-radius: 25%;
}
</style>