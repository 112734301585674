<template>
	<div>
	    <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
	      	<template #header>
	        	<p class="mb-0 text-secondary">Text-Editor</p>
	      	</template>
			<!--card-body-->
			<b-form-group>
	        <b-alert v-if="message" variant="danger"  show dismissible>{{ message }}</b-alert>
	        </b-form-group>
			<div class="m-1" id="botton-group-1">
			    <b-button-toolbar>
			      <b-button-group class="mr-1">
			        <b-button size="sm" v-b-tooltip.bottom variant="secondary" title="Insert a pause" v-on:click.prevent="openModalBreak">
			          <b-icon icon="file-earmark-break-fill" variant="warning" font-scale="1" aria-hidden="true"></b-icon>
			        </b-button>
			        <b-button v-show="getAllowSet" size="sm" v-b-tooltip.bottom variant="secondary" title="Emphasizing a sentense" v-on:click.prevent="emphasizing">
			          <b-icon icon="alt" variant="warning" font-scale="1" aria-hidden="true"></b-icon>
			        </b-button>
			        <b-button v-show="getAllowSet" size="sm" v-b-tooltip.bottom variant="secondary" title="Spell out a word" v-on:click.prevent="spellOut">
			          <b-icon icon="spellcheck" variant="warning" font-scale="1" aria-hidden="true"></b-icon>
			        </b-button>
			        
			        <b-button v-show="getAllowSet" size="sm" v-b-tooltip.bottom variant="secondary" title="Customize a full sentense speech" @click.prevent="openModalSet">
			          <b-icon icon="soundwave" variant="warning" font-scale="1" aria-hidden="true"></b-icon>
			        </b-button>
			      </b-button-group>
			      
			       <b-button size="sm" v-b-tooltip.bottom variant="primary" title="Generate text with AI Text Module. Try it!" @click.prevent="goToAIGenerator">
			        AI Text <b-badge variant="light">New</b-badge></b-button>
  
			    </b-button-toolbar>
			</div>
			<b-form-textarea
			  class="m-1 border-secondary"
		      id="textinput"
		      size="sm"
		      placeholder="Enter your text here..."
		      rows="7"
		      :value="getInputText"
		      @input="updateCount"
		    ></b-form-textarea>
			<!--/card-body-->
			<template #footer>
		        <p id="textCounter" class="my-0 text-secondary" :value="getEditorCount"><b-icon icon="pencil-square" variant="secondary" font-scale="1" aria-hidden="true"></b-icon> Characters count: {{getEditorCount}}/5000</p>
		        <p class="my-0 text-secondary"> * Starred voices like (Joey*) don't support custom editing, except adding pauses</p>
		    </template>
    	</b-card>
	   

		<b-modal
		  @ok="saveClicked()"
		  ref="customs-speech" 
		  ok-title="Save"
		  size="sm"
		  button-size="sm"
		  body-bg-variant="dark"
		  footer-bg-variant="dark"
		  footer-border-variant="secondary" 
		  hide-header 
		  no-close-on-esc 
		  no-close-on-backdrop static>
		  	<div class="justify-content-around my-0">
			  	<b-form>
			  		<p id="p-set-cust-css" :value="rate">(-) Rate[{{rate}}] (+)</p>
			    	<b-form-input type="range" size="sm" class="custom-range" min="0" max="5" step="1" id="customRate" v-model="rate"></b-form-input>
			  		<p id="p-set-cust-css" :value="volume">(-) Volume[{{volume}}] (+)</p>
			    	<b-form-input type="range" class="custom-range" min="0" max="6" step="1" id="customVolume" v-model="volume"></b-form-input>
			  		<p id="p-set-cust-css" :value="pitch">(-) Pitch[{{pitch}}] (+)</p>
			    	<b-form-input type="range" class="custom-range" min="0" max="5" step="1" id="customPitch" v-model="pitch"></b-form-input>
			  	</b-form>
		  	</div>
		</b-modal>
		<b-modal
			class="border-0"
		  @ok="saveBreakClicked()"
		  ref="custom-break" 
		  ok-title="Save"
		  size="sm"
		  button-size="sm"
		  body-bg-variant="dark"
		  footer-bg-variant="dark"
		  footer-border-variant="secondary"
		  hide-header 
		  no-close-on-esc 
		  no-close-on-backdrop static>
		  	<div class="justify-content-around my-0">
			  	<b-form>
			  		<p id="p-set-cust-css" :value="breakd">(-) {{breakd}}ms (+)</p>
			    	<b-form-input type="range" size="sm" class="custom-range" min="500" max="3000" step="500" id="customBreak" v-model="breakd"></b-form-input>
			    	<span></span>
			  	</b-form>
		  	</div>
		</b-modal>
	
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
 	data(){
 		return {
 			message:'',
 			rate:2,
 			volume:3,
 			pitch:2,
 			breakd:500,	
 		}
 	},
 	computed: {
	    ...mapGetters(["getEditorCount", "getAllowSet", "getInputText"]),
	},
 	methods: {

 		goToAIGenerator(){
 			this.$router.push('/AIgenerator').catch(()=>{});
 		},
 		updateCount(){
 			this.message ='';
 			this.$store.dispatch("updateEditorCount");
 		},
 		
 		emphasizing(){
 			this.message ='';
 			let textArea = document.getElementById("textinput");
	        let curStr = textArea.selectionStart;
	        let curEnd = textArea.selectionEnd;
	        if (curStr !== curEnd) {
	          this.$store.dispatch("sEmphasizing");
	          this.$store.dispatch("updateEditorCount");
	        } else {
	          this.message = 'Please select a sentence first!';
	          }
 		},

 		spellOut(){
 			this.message ='';
 			let textArea = document.getElementById("textinput");
	        let curStr = textArea.selectionStart;
	        let curEnd = textArea.selectionEnd;
	        if (curStr !== curEnd) {
	          this.$store.dispatch("sSpellOut");
	          this.$store.dispatch("updateEditorCount");
	        } else {
	          this.message = 'Please select a word first!';
	          }	
 		},

 		openModalSet(){
 			this.message ='';
	        let textAreaIn = document.getElementById("textinput");
	        let curStr = textAreaIn.selectionStart;
	        let curEnd = textAreaIn.selectionEnd;
	        if (curStr !== curEnd) {
	          this.$refs['customs-speech'].show();
	          let rateElm = document.getElementById("customRate");
	          rateElm.value = 2;
	          let volumeElm = document.getElementById("customVolume");
	          volumeElm.value = 3;
	          let pitchElm = document.getElementById("customPitch");
	          pitchElm.value = 2;
	          //set default settings
	          this.rate =2;
	          this.volume =3;
	          this.pitch =2;
	        } else {
	          this.message = 'Please select a sentence first!';
	          }
    	},
 		
 		saveClicked(){
 			this.$store.dispatch("sSaveClicked", {sRate: this.rate,sVolume: this.volume,sPitch: this.pitch});
 			this.$store.dispatch("updateEditorCount");	
 		},

 		openModalBreak(){
 		  this.message ='';
	      let textAreaIn = document.getElementById("textinput");
          let curStr = textAreaIn.selectionStart;
          let x = textAreaIn.value;
          let endPosition = x.length;
          if ((curStr !== 0) && (curStr !== endPosition)) {
            this.$refs['custom-break'].show();
            let breakElm = document.getElementById("customBreak");
	          breakElm.value = 500;
	          this.breakd =500;
          } else {
            this.message ='Place the cursor at the pause position inside text!';
            }
    	},
    	saveBreakClicked(){
 			this.$store.dispatch("sBreathing", {sBreak: this.breakd});
 			this.$store.dispatch("updateEditorCount");
 		}
 	}
};

</script>

<style scoped>
/* #botton-group-1 {
 	text-align: left;
	display: block; 
 }

 button {
 	width: 150px;
 	font-size: 12px;
 }*/
 .align-lft{
 	text-align: left;
 }
.modal-content {
	border: black;
}
 #p-set-cust-css {
 	color: grey;
 	text-align: center;
 	margin-bottom: 0px;
 	margin-top: 10px;
 }
</style>