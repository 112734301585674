import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({encodingType: 'rc4', isCompression: false});
ls.removeAll();
import { auth } from './auth.module';
import UserService from '../services/user.service';
import AuthService from '../services/auth.service';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    //speechor state
    xmyJson:[],
    textAreaVal:'',
    langOptions:[],
    totalCharacters:0,
    resetRate:2,
    resetVolume:2,
    resetPitch:2,
    availableVoices:[],
    records:[],
    musics:[],
    products:[],
    totalActors:1,
    showSpinner: false,
    currentUId: '',
    currentBalance:['0','0'],
    boostNow:false,
    processingSpinner: true,
    failedSpinner: [false,''],
    successSpinner: false,
    editorCount:0,
    allowSet:true,
    newpass:[false,''],
    failedpass:[false,''],
    newemail:[false,''],
    failedemail:[false,''],
    sendCodeState:[false,'',''],
    resetPassState:[false,'',''],
    verifiedCode:'',
    verifiedEmail:'',
    codeformshow:true,

    //Gpt state
    textInputGpt:'',
    textInputGptCount:0,
    textOutputGptCount:0,
    gptrecords:[],
    currentCredit:['0','0'],
    gptproducts:[],
    forgotattemps:"0",
    passcodeattemps:"0",

    //presentor state
    presProducts:[],
    presCredit:['0','0'],
    presrecords:[],

    pptxprocessingSpinner:false,
    pptxsuccessSpinner:false,
    pptxfailedSpinner: [false,''],

    //Imagine
    imgProducts:[],
    imgrecords:[],
    imgCredit:['0','0'],
    imgdownloadError:false,
    allowedVarImg: ['256_256', '512_512', '1024_1024', 'st_1024_1024', 'hd_1024_1024'],

    //Aff state

    affrecords:[],
    afftopfive:[],
    affsignedup: false,
    affId: '',
    affBalance:0,
    affpartners: 0,
    affthismonth: 0,
    affalltime: 0,
    wmessage:['',''],
    dismissCountDown: 0,

    paypalCodeState:[false,'',''],
    paypalCodeSent:false,
    pplemail:['', false],
    pplemailexist:false,

    isaffActive:true,
    presdownloadError:false,

    accrecords:[],
    srvrecords:[],

    discordId:'',
    discordCodeState:[false,'',''],
    discordCodeSent:false,
    srvdiscordCodeState:[false,'',''],
    srvdiscordCodeSent:false,
    
    signUpState:[false,'',''],
    signupformshow: true,
    signcodeformshow: false,
    emailsignupcodeattemps: "0",
    signupattamps:"0",
    countries:[],
    phoneCode:[],
         
  },
  plugins: [
  
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  mutations: {
    //Speechor mutations
    SET_MYJSON(state, xmyJson){
      state.xmyJson = xmyJson;
    },
    SET_NEWPASSWORD(state, newpass){
      state.newpass = newpass;
    },
    SET_FAILEDPASSWORD(state, failedpass){
      state.failedpass = failedpass;
    },
    SET_NEWEMAIL(state, newemail){
      state.newemail = newemail;
    },
    SET_FAILEDEMAIL(state, failedemail){
      state.failedemail = failedemail;
    },
    SET_SENDCODESTATE(state, sendCodeState){
      state.sendCodeState = sendCodeState;
    },
    SET_RESETPASSSTATE(state, resetPassState){
      state.resetPassState = resetPassState;
    },
    SET_VERIFCODE(state, verifiedCode){
      state.verifiedCode = verifiedCode;
    },
    SET_VERIFEMAIL(state, verifiedEmail){
      state.verifiedEmail = verifiedEmail;
    },
    SET_CODEFORMSHOW(state, codeformshow){
      state.codeformshow = codeformshow;
    },
    SET_BOOST_NOW(state, boostNow){
      state.boostNow = boostNow;
    },
    SET_ALLOW_SET(state, allowSet){
      state.allowSet = allowSet;
    },
    SET_EDITOR_COUNT(state, editorCount){
      state.editorCount = editorCount;
    },
    SET_SUCCESS_SPINNER(state, successSpinner){
      state.successSpinner = successSpinner;
    },
    SET_FAILED_SPINNER(state, failedSpinner){
      state.failedSpinner = failedSpinner;
    },
    SET_PROCESS_SPINNER(state, processingSpinner){
      state.processingSpinner = processingSpinner;
    },
    SET_TEXTAREA(state, textAreaVal){
      state.textAreaVal = textAreaVal;
    },
    SET_AVAILABLE_LANGUAGES(state, langOptions) {
      state.langOptions = langOptions;
    },
    SET_AVAILABLE_VOICES(state, availableVoices) {
      state.availableVoices = availableVoices;
    },
    SET_TOTAL_ACTORS(state, totalActors){
      state.totalActors = totalActors;
    },
    SET_RECORDS(state, records) {
      state.records = records;
    },
    SET_MUSICS(state, musics) {
      state.musics = musics;
    },
    SET_TOTAL_CHARACTERS(state, totalCharacters) {
      state.totalCharacters = totalCharacters;
    },
    SET_SPINNER(state, spinner) {
      state.showSpinner = spinner;
    },
    SET_CURRENT_UID (state, currentUId){
      state.currentUId = currentUId;
    },
    SET_BALANCE (state, currentBalance){
      state.currentBalance = currentBalance;
    },
    SET_PRODUCTS (state, products){
      state.products = products;
    },
    //GPT mutations
    SET_GPTPRODUCTS (state, gptproducts){
      state.gptproducts = gptproducts;
    },
    SET_INPUT_TEXT_GPT(state, textInputGpt){
      state.textInputGpt = textInputGpt;
    },
    SET_INPUT_TEXT_GPT_COUNT(state, textInputGptCount){
      state.textInputGptCount = textInputGptCount;
    },
    SET_OUTPUT_TEXT_GPT_COUNT(state, textOutputGptCount){
      state.textOutputGptCount = textOutputGptCount;
    },
    SET_GPTRECORDS(state, gptrecords) {
      state.gptrecords = gptrecords;
    },
    SET_GPT_CREDIT (state, currentCredit){
      state.currentCredit = currentCredit;
    },

    SET_FORGOTATTEMPS (state, forgotattemps){
      state.forgotattemps = forgotattemps;
    },
    SET_PASSCODEATTEMPS (state, passcodeattemps){
      state.passcodeattemps = passcodeattemps;
    },
    //presentor mutations

    SET_PRESPRODUCTS (state, presProducts){
      state.presProducts = presProducts;
    },
    SET_PRES_CREDIT (state, presCredit){
      state.presCredit = presCredit;
    },
    SET_PRESRECORDS(state, presrecords) {
      state.presrecords = presrecords;
    },

    SET_PPTXSUCCESS_SPINNER(state, pptxsuccessSpinner){
      state.pptxsuccessSpinner = pptxsuccessSpinner;
    },
    SET_PPTXFAILED_SPINNER(state, pptxfailedSpinner){
      state.pptxfailedSpinner = pptxfailedSpinner;
    },
    SET_PPTXPROCESS_SPINNER(state, pptxprocessingSpinner){
      state.pptxprocessingSpinner = pptxprocessingSpinner;
    },

    //Imagine mutations
    SET_IMG_CREDIT (state, imgCredit){
      state.imgCredit = imgCredit;
    },
    SET_IMG_RECORDS(state, imgrecords) {
      state.imgrecords = imgrecords;
    },
    SET_IMGDOWNLOADERR(state, imgdownloadError){
      state.imgdownloadError = imgdownloadError;
    },
    SET_IMGPRODUCTS (state, imgProducts){
      state.imgProducts = imgProducts;
    },


    //Affiliate program mutations
    SET_AFFRECORDS(state, affrecords) {
      state.affrecords = affrecords;
    },
    SET_TOPFIVE(state, afftopfive) {
      state.afftopfive = afftopfive;
    },

    SET_SIGNEDUP(state, affsignedup) {
      state.affsignedup = affsignedup;
    },
    SET_AFFID(state, affId) {
      state.affId = affId;
    },
    SET_AFF_BALANCE(state, affBalance) {
      state.affBalance = affBalance;
    },
    SET_PARTNERS(state, affpartners) {
      state.affpartners = affpartners;
    },
    SET_AFF_THISM(state, affthismonth) {
      state.affthismonth = affthismonth;
    },
    SET_AFF_ALLT(state, affalltime) {
      state.affalltime = affalltime;
    },
    SET_WMESSAGE(state, wmessage) {
      state.wmessage = wmessage;
    },
    SET_WDISMISSALERTE(state, dismissCountDown) {
      state.dismissCountDown = dismissCountDown;
    },

    //paypal setting
    SET_PAYCODESTATE(state, paypalCodeState){
      state.paypalCodeState = paypalCodeState;
    },
    SET_PAYCODESENT(state, paypalCodeSent){
      state.paypalCodeSent = paypalCodeSent;
    },

    SET_PPLEMAIL(state, pplemail){
      state.pplemail = pplemail;
    },

    SET_PPLEXIST(state, pplemailexist){
      state.pplemailexist = pplemailexist;
    },


    SET_ISAFFACTIVE(state, isaffActive){
      state.isaffActive = isaffActive;
    },
    SET_PRESDOWNLOADERR(state, presdownloadError){
      state.presdownloadError = presdownloadError;
    },

    SET_ACC_RECORDS(state, accrecords){
      state.accrecords = accrecords;
    },

    SET_SRV_RECORDS(state, srvrecords){
      state.srvrecords = srvrecords;
    },

    SET_DISCORD_ID(state, discordId){
      state.discordId = discordId;
    },
   
    SET_DISCODESTATE(state, discordCodeState){
      state.discordCodeState = discordCodeState;
    },

    SET_DISCODESENT(state, discordCodeSent){
      state.discordCodeSent = discordCodeSent;
    },

    SET_SRVDISCODESTATE(state, srvdiscordCodeState){
      state.srvdiscordCodeState = srvdiscordCodeState;
    },

    SET_SRVDISCODESENT(state, srvdiscordCodeSent){
      state.srvdiscordCodeSent = srvdiscordCodeSent;
    },

    SET_COUNTRIES(state, countries){
      state.countries = countries;
    },
    SET_PHONECODE(state, phoneCode){
      state.phoneCode = phoneCode;
    },
    SET_SIGNUPSTATE(state, signUpState){
      state.signUpState = signUpState;
    },
    SET_SIGNFORMSHOW(state, signupformshow){
      state.signupformshow = signupformshow;
    },
    SET_SIGNCODEFORMSHOW(state, signcodeformshow){
      state.signcodeformshow = signcodeformshow;
    },

    SET_SIGNGATTEMPS (state, signupattamps){
      state.signupattamps = signupattamps;
    },
    SET_EMAILCODESIGNUPEATTEMPS (state, emailsignupcodeattemps){
      state.emailsignupcodeattemps = emailsignupcodeattemps;
    },
   
   

  },

  actions: {

    async initialize({commit}){
      commit("SET_AVAILABLE_VOICES", {});
      //commit("SET_TEXTAREA", '');
      commit("SET_CODEFORMSHOW", true);
      commit("SET_RESETPASSSTATE", [false, '','']);
      commit("SET_SENDCODESTATE", [false, '','']);
      commit("SET_SIGNUPSTATE", [false, '','']);
      commit("SET_SIGNFORMSHOW", true);
      commit("SET_SIGNCODEFORMSHOW", false);

      commit("SET_SPINNER", false);
      commit("SET_SUCCESS_SPINNER", false);
      commit("SET_FAILED_SPINNER", [false,'']);
      commit("SET_PROCESS_SPINNER", true);
      commit("SET_ALLOW_SET", true);
      commit("SET_NEWPASSWORD", [false,'']);
      commit("SET_FAILEDPASSWORD", [false,'']);
      commit("SET_NEWEMAIL", [false,'']);
      commit("SET_FAILEDEMAIL", [false,'']);
    },

    //Speechor Actions
    async payment({commit, dispatch},{product, router}){
          commit("SET_SPINNER", true);
          const myJson = await UserService.payment(product)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302'){
                          commit("SET_SPINNER", false);
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return '';
                        } 
                        else if (reStatus == '111') {
                          return '';
                        }
                        else {
                          return response.data.response.result;
                        }
                    } else {
                      return '';
                    }
                    });
          commit("SET_SPINNER", false);
          window.location.replace(myJson);  
          
                 
    },
    
    async getActorsCount({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getVoices()
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                      var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                    } else {
                      return [];
                    }
                    });
      if (myJson.length > 0){
        commit("SET_MYJSON", myJson);
        const countActors = myJson.length;
        commit("SET_TOTAL_ACTORS", countActors);
        //get languages list
        const langJson = await myJson.map(langObj => {
            const lObj = {};
            lObj.text = langObj.LangName;
            lObj.value = langObj.Language; 
            lObj.disabled = false;
            return lObj;
            });
        //get unique langs
        var a = [];
        var len = langJson.length;
        for (var i = 0; i < len; i++) {a[langJson[i]['text']] = langJson[i]; }
        var b = [];
        for ( var key in a ) {b.push(a[key]);}
        commit("SET_AVAILABLE_LANGUAGES", b);
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
      
    },

    async getVoices({ commit }, { language, gender, myJson }){
      commit("SET_SPINNER", true);
      commit("SET_ALLOW_SET", true);
      if (myJson.length > 0){
        //get filered available voice actors
        const filteredJson = await myJson.filter(obj => obj.Gender == gender && obj.Name.includes(language));
        const mappedJson = await filteredJson.map(singleObj => {
            const rObj = {};
            rObj.text = singleObj.Actor;
            rObj.value = singleObj.Name; 
            rObj.disabled = false;
            return rObj;
            });
        commit("SET_AVAILABLE_VOICES", mappedJson);
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }  
    },

    async getRecords({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getRecords()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var resStatus = response.data.response.status;
                        if(resStatus == '300' || resStatus == '301' || resStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                        
                      }
                      return [];
                      
                    });
      if ('records' in myJson){
        //total characters used by the current user:
        var totalCharacters = 0;
        var len = myJson.records.length;
        for(var i=0; i<len; i++){
          totalCharacters+= parseInt(myJson.records[i].Characters);
        }
        //map items for tableview:
        const mappedJson = await myJson.records.map(singleObj => {
            const rObj = {};
            rObj.actor = singleObj.Actor;
            rObj.id = singleObj.rId;
            rObj.language = singleObj.Language;
            rObj.text = singleObj.Text; 
            rObj.characters = singleObj.Characters; 
            rObj.date = singleObj.Date;
            return rObj;
            });
        const uId = myJson.userId;
        var used = myJson.usedchars.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        var unused = myJson.unusedchars.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        const balance = [used, unused];
        
        commit("SET_CURRENT_UID", uId);
        commit("SET_BALANCE", balance);
        commit("SET_TOTAL_CHARACTERS", totalCharacters);
        commit("SET_RECORDS", mappedJson);
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },
    async getMusics({ commit, dispatch },{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getMusics()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var rStatus = response.data.response.status;
                        if(rStatus == '300' || rStatus == '301' || rStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                      }
                      return [];
                      
                    });
      if (myJson.length > 0){
        //map items for musictable:
        const mappedJson = await myJson.map(singleObj => {
            const rObj = {};
            rObj.name = singleObj.Name;
            rObj.artist = singleObj.Artist;
            rObj.mood = singleObj.Mood;
            rObj.play = singleObj.Play; 
            
            return rObj;
            });
        commit("SET_MUSICS", mappedJson);
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },
    async getProducts({ commit, dispatch }, {router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getProducts()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var rStatus = response.data.response.status;
                        if(rStatus == '300' || rStatus == '301' || rStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                      }
                      return []; 
                    });
      if (myJson.length > 0){
        //map items:
        const mappedJson = await myJson.map(singleObj => {
            const rObj = {};
            rObj.Product = singleObj.Product;
            rObj.Price = singleObj.Price;
            rObj.Basic = singleObj.Basic.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            rObj.Bonus = singleObj.Bonus.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            rObj.Total = String(singleObj.Total).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            return rObj;
            });

        const ttsproducts = mappedJson.filter(item => {
          if(mappedJson.indexOf(item) < 3){
            return true;}
          });
        const tttproducts = mappedJson.filter(item =>  {
          if(mappedJson.indexOf(item) > 2 && mappedJson.indexOf(item) < 6){
            return true;}
          });

        const ttpproducts = mappedJson.filter(item =>  {
          if(mappedJson.indexOf(item) > 5 && mappedJson.indexOf(item) < 9){
            return true;}
        });

        const ttiproducts = mappedJson.filter(item =>  {
          if(mappedJson.indexOf(item) > 8){
            return true;}
        });
 
        commit("SET_PRODUCTS", ttsproducts);
        commit("SET_GPTPRODUCTS", tttproducts);
        commit("SET_PRESPRODUCTS", ttpproducts);
        commit("SET_IMGPRODUCTS", ttiproducts);

        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    }, 

    async saveSpeech({commit,dispatch, state},{language,actor,router}){
      commit("SET_SUCCESS_SPINNER", false);
      commit("SET_PROCESS_SPINNER", true);
      if (!language == '' && !actor == '' && !state.textAreaVal == '') {
        var mapObj = {
                    "[BREATHING B='500']" : "<break time='500ms'/>",
                    "[BREATHING B='1000']" : "<break time='1000ms'/>",
                    "[BREATHING B='1500']" : "<break time='1500ms'/>",
                    "[BREATHING B='2000']" : "<break time='2000ms'/>",
                    "[BREATHING B='2500']" : "<break time='2500ms'/>",
                    "[BREATHING B='3000']" : "<break time='3000ms'/>",
                    "[SPELL-OUT-START]" : "<say-as interpret-as='characters'>",
                    "[SPELL-OUT-END]" : "</say-as>",
                    "[EMPHASIZE-START]" : "<emphasis level='moderate'>",
                    "[EMPHASIZE-END]" : "</emphasis>",
                    "[CUSTOM-START":"<prosody", 
                    "R='0'" :"rate='x-slow'",
                    "R='1'" :"rate='slow'",
                    "R='2'" :"rate='default'", 
                    "R='3'" :"rate='medium'",
                    "R='4'" :"rate='fast'",
                    "R='5'" :"rate='x-fast'",
                    "V='0'": "volume='silent'", 
                    "V='1'": "volume='x-soft'", 
                    "V='2'": "volume='soft'",
                    "V='3'": "volume='default'", 
                    "V='4'": "volume='medium'", 
                    "V='5'": "volume='loud'", 
                    "V='6'": "volume='x-loud'",  
                    "P='0']": "pitch='x-low'>", 
                    "P='1']": "pitch='low'>", 
                    "P='2']": "pitch='default'>",
                    "P='3']": "pitch='medium'>", 
                    "P='4']": "pitch='high'>", 
                    "P='5']": "pitch='x-high'>",
                    "[CUSTOM-END]": "</prosody>"};
        var rex = /\[BREATHING B='500'\]|\[BREATHING B='1000'\]|\[BREATHING B='1500'\]|\[BREATHING B='2000'\]|\[BREATHING B='2500'\]|\[BREATHING B='3000'\]|\[SPELL-OUT-START\]|\[SPELL-OUT-END\]|\[EMPHASIZE-START\]|\[EMPHASIZE-END\]|\[CUSTOM-START|R='0'|R='1'|R='2'|R='3'|R='4'|R='5'|V='0'|V='1'|V='2'|V='3'|V='4'|V='5'|V='6'|P='0'\]|P='1'\]|P='2'\]|P='3'\]|P='4'\]|P='5'\]|\[CUSTOM-END\]/gi;
        var str = state.textAreaVal.replace(rex, function(matched){
                                                return mapObj[matched];
                                              });
        var ssml = '<speak>' + str + '</speak>';         
        await UserService.saveSpeech(language, actor, ssml, state.textAreaVal)
                    .then(response => {
                      if(response.data!=='' && response.data.constructor === Object){
                        var respStatus = response.data.response.status;
                        if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                          commit("SET_PROCESS_SPINNER", false);
                          var expMessage = [true,'Session Expired!'];
                          commit("SET_FAILED_SPINNER", expMessage);
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                        } 
                        else if(respStatus == '110'){
                          commit("SET_PROCESS_SPINNER", false);
                          var bMessage = [true,'Your balance is low! Please click Boost Balance tab.'];
                          commit("SET_BOOST_NOW", true);
                          commit("SET_FAILED_SPINNER", bMessage);
                        }
                        else if(respStatus == '112'){
                          commit("SET_PROCESS_SPINNER", false);
                          var sMessage = [true,'Your Storage is low! Download files and free some space!'];
                          commit("SET_FAILED_SPINNER", sMessage);
                        }
                        else if(respStatus == '113'){
                          commit("SET_PROCESS_SPINNER", false);
                          var ttsMessage = [true,'Oops! Something went wrong please try again or find support!'];
                          commit("SET_FAILED_SPINNER", ttsMessage);
                        }
                        else {
                          dispatch("getRecords", {router: this.$router});
                          commit("SET_PROCESS_SPINNER", false);
                          commit("SET_SUCCESS_SPINNER", true);
                        }
                        
                      } else {
                        commit("SET_PROCESS_SPINNER", false);
                        var eMessage = [true,'Oops Something went wrong, try again!'];
                        commit("SET_FAILED_SPINNER", eMessage);
                      }
                    });
      }

    },
    
    async downloadRecord({state},{rid}){
        await UserService.downloadRecord(state.currentUId, rid);
    },



    async changePass({commit, dispatch},{oldpass, newpass, router}){
        commit("SET_SPINNER", true);
        commit("SET_NEWPASSWORD", [false,'']);
        commit("SET_FAILEDPASSWORD", [false,'']);
        await UserService.changePass(oldpass, newpass)
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var respStatus = response.data.response.status;
                        if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                        } 
                        else if(respStatus == '115'){
                          var fMessage = [true,'Oops! Old password incorrect!'];
                          commit("SET_FAILEDPASSWORD", fMessage);
                          commit("SET_SPINNER", false);
                        }
                        else {
                          var sMessage = [true,'Password changed! Please logout then login with your new password.'];
                          commit("SET_NEWPASSWORD", sMessage);
                          commit("SET_SPINNER", false);
                        }
                      } else{
                        fMessage = [true,'Oops! Something went wrong, please try again later or contact us.'];
                        commit("SET_FAILEDPASSWORD", fMessage);
                        commit("SET_SPINNER", false);
                      }
                      
                    });
    },

    async changeEmail({commit, dispatch},{oldemail, newemail, router}){
        commit("SET_SPINNER", true);
        commit("SET_NEWEMAIL", [false,'']);
        commit("SET_FAILEDEMAIL", [false,'']);
        await UserService.changeEmail(oldemail, newemail)
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var respStatus = response.data.response.status;
                        if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                        } 
                        else if(respStatus == '116'){
                          var fMessage = [true,'Oops! Old email incorrect!'];
                          commit("SET_FAILEDEMAIL", fMessage);
                          commit("SET_SPINNER", false);
                        }
                        else {
                          var sMessage = [true,'Email changed! Please logout then login with your new email.'];
                          commit("SET_NEWEMAIL", sMessage);
                          commit("SET_SPINNER", false);
                        }
                      } else{
                        fMessage = [true,'Oops! Something went wrong, please try again later or contact us.'];
                        commit("SET_FAILEDEMAIL", fMessage);
                        commit("SET_SPINNER", false);
                      }
                      
                    });
    },

    initSignUpState({commit}){
      commit("SET_SIGNUPSTATE", [false, '','']);
    },

    async signupCountriesSelect({commit}){
      const myJson = require('../assets/country.json');
      const countryJson = await myJson.map(langObj => {
            const lObj = {};
            lObj.text = langObj.country_en;
            lObj.value = langObj.country_code; 
            lObj.disabled = false;
            return lObj;
            });

      const countryCode = await myJson.map(langObj => {
            const lObj = {};
            lObj.text = "(+"+langObj.phone_code+") " +langObj.country_en;
            lObj.value = "+"+langObj.phone_code; 
            lObj.disabled = false;
            return lObj;
            });
      commit("SET_COUNTRIES", countryJson);
      commit("SET_PHONECODE", countryCode);
    },

    async SignUp({state, commit, dispatch},{fullname, email, country, phone, password,retoken,referrer,router}){
        commit("SET_SIGNUPSTATE", [false, '','']);
        commit("SET_SPINNER", true);
        dispatch("setCookie", {cookieName: 'SGN_COD_ATPS_SPR_FTH', commitName:"SET_EMAILCODESIGNUPEATTEMPS", path:"/Signup", attemps:"0"});
        dispatch("getCookie", {cname: 'SGN_ATPS_SPR_FTH', commitVar:"SET_SIGNGATTEMPS"});
        if( parseInt(state.signupattamps) < 6){
          await AuthService.SignUp(fullname, email, country, phone, password,retoken,referrer)
                      .then(response => {
                        
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                            dispatch("setCookie", {cookieName: 'SGN_ATPS_SPR_FTH', commitName:"SET_SIGNGATTEMPS", path:"/Signup", attemps:state.signupattamps});
                          } 
                          else if(respStatus == '118'){
                            commit("SET_SIGNUPSTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                            dispatch("setCookie", {cookieName: 'SGN_ATPS_SPR_FTH', commitName:"SET_SIGNGATTEMPS", path:"/Signup", attemps:state.signupattamps});
                            
                          }
                          else if (respStatus == '200'){
                            commit("SET_SIGNUPSTATE", [true, response.data.response.result,'success']);
                            dispatch("setCookie", {cookieName: 'SGN_ATPS_SPR_FTH', commitName:"SET_SIGNGATTEMPS", path:"/Signup", attemps:state.signupattamps});
                            commit("SET_VERIFEMAIL", email);
                            commit("SET_SIGNFORMSHOW", false);
                            commit("SET_SIGNCODEFORMSHOW", true);
                            commit("SET_SPINNER", false);
                          } else {
                              commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                              dispatch("setCookie", {cookieName: 'SGN_ATPS_SPR_FTH', commitName:"SET_SIGNGATTEMPS", path:"/Signup", attemps:state.signupattamps});
                          }
                        } else{
                          commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                          dispatch("setCookie", {cookieName: 'SGN_ATPS_SPR_FTH', commitName:"SET_SIGNGATTEMPS", path:"/Signup", attemps:state.signupattamps});
                        }

                      });
        } else {
          commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, Try again later!', 'danger']);
          commit("SET_SPINNER", false);
        }
    },

    async verifyEmailSignupCode({state, commit, dispatch},{code, email, retoken, router}){
      commit("SET_SIGNUPSTATE", [false, '','']);
        commit("SET_SPINNER", true);
        dispatch("getCookie", {cname: 'SGN_COD_ATPS_SPR_FTH', commitVar:"SET_EMAILCODESIGNUPEATTEMPS"});
        if(parseInt(state.emailsignupcodeattemps) < 3){
          await AuthService.verifyEmailSignupCode(code, email, retoken)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          
                          else if(respStatus == '118'){
                            commit("SET_SIGNUPSTATE", [true, response.data.response.message,'danger']);
                            dispatch("setCookie", {cookieName: 'SGN_COD_ATPS_SPR_FTH', commitName:"SET_EMAILCODESIGNUPEATTEMPS", path:"/Signup", attemps:state.emailsignupcodeattemps}); 
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            commit("SET_SIGNUPSTATE", [true, response.data.response.result,'success']);
                            commit("SET_SIGNCODEFORMSHOW", false);
                            commit("SET_SPINNER", false);
                          } else {
                              commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SIGNUPSTATE", [true,'Oops! Something went wrong, Try again later!', 'danger']);
          commit("SET_SPINNER", false);
        }
    },

    async sendEmailPassCode({state, commit, dispatch},{cpath, cprefix, email, retoken, router}){
        commit("SET_SENDCODESTATE", [false, '','']);
        commit("SET_SPINNER", true); 
        dispatch("setCookie", {cookieName: 'COD_ATPS_SPR'+cprefix, commitName:"SET_PASSCODEATTEMPS", path:"/"+cpath, attemps:"0"});
        dispatch("getCookie", {cname: 'FRGT_ATPS_SPR'+cprefix, commitVar:"SET_FORGOTATTEMPS"});
        if(parseInt(state.forgotattemps) < 3){
          await AuthService.sendEmailPassCode(email, retoken)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '116' || respStatus == '118'){
                            commit("SET_SENDCODESTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                            
                            dispatch("setCookie", {cookieName: 'FRGT_ATPS_SPR'+cprefix, commitName:"SET_FORGOTATTEMPS", path:"/"+cpath, attemps:state.forgotattemps});
                            
                          }
                          else if (respStatus == '200'){
                            commit("SET_SENDCODESTATE", [true, response.data.response.result,'success']);
                            dispatch("setCookie", {cookieName: 'FRGT_ATPS_SPR'+cprefix, commitName:"SET_FORGOTATTEMPS", path:"/"+cpath, attemps:state.forgotattemps});
                            commit("SET_VERIFEMAIL", email);
                            commit("SET_CODEFORMSHOW", false);
                            commit("SET_SPINNER", false);
                          } else {
                              commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SENDCODESTATE", [true,'Email incorrect!', 'danger']);
          commit("SET_SPINNER", false);
        }
    },
    
    async verifyPassCode({state, commit, dispatch},{cpath, cprefix, reason, code, email,retoken, router}){
      commit("SET_SENDCODESTATE", [false, '','']);
        commit("SET_SPINNER", true);
        dispatch("getCookie", {cname: 'COD_ATPS_SPR'+cprefix, commitVar:"SET_PASSCODEATTEMPS"});
        if(parseInt(state.passcodeattemps) < 3){
          await AuthService.verifyPassCode(code, email, retoken)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          
                          else if(respStatus == '118'){
                            commit("SET_SENDCODESTATE", [true, response.data.response.message,'danger']);
                            dispatch("setCookie", {cookieName: 'COD_ATPS_SPR'+cprefix, commitName:"SET_PASSCODEATTEMPS", path:"/"+cpath, attemps:state.passcodeattemps});
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            commit("SET_SENDCODESTATE", [true, response.data.response.result,'success']);
                            commit("SET_VERIFCODE", code);
                            
                            if(reason == 'passreset'){
                              setTimeout(function() { router.push('/PasswordReset').catch(()=>{}); }, 500);
                              
                            }else{
                              setTimeout(function() { router.push('/login').catch(()=>{}); }, 500);
                              
                            }
                            commit("SET_SPINNER", false);

                          } else {
                              commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SENDCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SENDCODESTATE", [true,'Code is not valid!', 'danger']);
          commit("SET_SPINNER", false);
        }
    },

    setCookie({commit, dispatch},{cookieName,commitName,path,attemps}) {
      dispatch("getCookie", {cname: cookieName, commitVar: commitName});
      //set expire time
      const d = new Date();
      d.setTime(d.getTime() + (15 * 60 * 1000));
      let expires = ";expires="+d.toUTCString();
      
      if(attemps == "0"){
        document.cookie = cookieName + "=1" + expires + ";path=" + path + ";SameSite=None;secure";
        commit(commitName, "1");
      } else {
        const newattemps = parseInt(attemps)+1
        document.cookie = cookieName + "=" + newattemps + expires + ";path=" + path + ";SameSite=None;secure";
        commit(commitName, newattemps);
        dispatch("getCookie", {cname: cookieName, commitVar: commitName});
      }
    
    },

    getCookie({commit},{cname, commitVar}) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == '') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          const fc = c.substring(name.length, c.length);
          commit(commitVar, fc);
          return;
        } 
      } 
      commit(commitVar, "0");
      return;
    },
    
   
    
    async resetPass({state, commit, dispatch},{password, router}){
      commit("SET_RESETPASSSTATE", [false, '','']);
        commit("SET_SPINNER", true);
        if (state.verifiedCode !=='' && state.verifiedEmail !== ''){
          await AuthService.resetPass(state.verifiedCode, state.verifiedEmail, password)
                .then(response => {
                  if (response.data!=='' && response.data.constructor === Object){
                    var respStatus = response.data.response.status;
                    if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                      dispatch("auth/logout");
                      router.push('/login').catch(()=>{});
                      commit("SET_SPINNER", false);
                    } 
                    
                    else if(respStatus == '118'){
                      commit("SET_RESETPASSSTATE", [true, response.data.response.message,'danger']);
                      commit("SET_SPINNER", false);
                    }
                    else if (respStatus == '200'){
                      commit("SET_RESETPASSSTATE", [true, response.data.response.result,'success']);
                      commit("SET_SPINNER", false);
                      setTimeout(function() { router.push('/login').catch(()=>{}); }, 500);
                      
                    } else {
                        commit("SET_RESETPASSSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                        commit("SET_SPINNER", false);
                    }
                  } else{
                    commit("SET_RESETPASSSTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                    commit("SET_SPINNER", false);
                  }
                  
                });
        } else {
          router.push('/forgotPassword').catch(()=>{});
          commit("SET_SPINNER", false);
        }
    },

    async deleteRecords({commit, dispatch},{rds, router}){
        commit("SET_SPINNER", true);
        var rid = [];
        rds.forEach(item => {
          rid.push(item.id)
        })
        if (rid.length > 0){
          await UserService.deleteRecords(rid)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } else {
                            dispatch("getRecords", {router: this.$router});
                            commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SPINNER", false);
        }
    },

    updateTextArea({commit},{text}){
        commit("SET_TEXTAREA", text);
    },

    sBreathing({commit},{sBreak}){
          let textAreaIn = document.getElementById("textinput");
          let curStr = textAreaIn.selectionStart;
          let x = textAreaIn.value;
          let reText = x.slice(0,curStr) + "[BREATHING B='"+sBreak+"']" + x.slice(curStr);
          textAreaIn.value = reText;
          commit("SET_TEXTAREA", reText);    
    },

    sEmphasizing({commit}){
        let emphasizeTagStart = '[EMPHASIZE-START]';
        let emphasizeTagEnd = '[EMPHASIZE-END]';
        let textAreaIn = document.getElementById("textinput");
        let curStr = textAreaIn.selectionStart;
        let curEnd = textAreaIn.selectionEnd;
        let x = textAreaIn.value;
        let reText = x.slice(0,curStr) 
                      + emphasizeTagStart 
                      + x.slice(curStr, curEnd)
                      + emphasizeTagEnd
                      + x.slice(curEnd);
        textAreaIn.value = reText;
        commit("SET_TEXTAREA", reText);  
    },

    sSpellOut({commit}){
      let spellOutStart = '[SPELL-OUT-START]';
      let spellOutEnd = '[SPELL-OUT-END]';
      let textAreaIn = document.getElementById("textinput");
      let curStr = textAreaIn.selectionStart;
      let curEnd = textAreaIn.selectionEnd;
      let x = textAreaIn.value;
      let reText = x.slice(0,curStr) 
                      + spellOutStart 
                      + x.slice(curStr, curEnd)
                      + spellOutEnd
                      + x.slice(curEnd);
      textAreaIn.value = reText;
      commit("SET_TEXTAREA", reText); 
    },

    sSaveClicked({commit}, {sRate, sVolume, sPitch}){
      let customStart = "[CUSTOM-START R='" + sRate + "' V='" + sVolume + "' P='" + sPitch + "']"; 
      let customEnd = '[CUSTOM-END]';
      let textAreaIn = document.getElementById("textinput");
      let curStr = textAreaIn.selectionStart;
      let curEnd = textAreaIn.selectionEnd;
      let x = textAreaIn.value;
      let reText = x.slice(0,curStr) 
                      + customStart 
                      + x.slice(curStr, curEnd)
                      + customEnd
                      + x.slice(curEnd);
      textAreaIn.value = reText;
      commit("SET_TEXTAREA", reText);
    },
    updateEditorCount({commit, dispatch}){
      var textValue = document.getElementById("textinput").value;
      dispatch("updateTextArea", {text: textValue});
      //substract tags
      /*var result = [], m, rx =/\[(.*?)\]/g;
      while ((m=rx.exec(textValue)) !== null) {
        result.push(m[1]);
      }
      var tagsLength=0,i=0;
      for (i; i < result.length; i++) {
         tagsLength += result[i].length;
      }
      var totalTagLenght = tagsLength + (2*result.length);
      var textCount = textValue.length - totalTagLenght;*/
      commit("SET_EDITOR_COUNT", textValue.length);
      //limit depassed warning
      var element = document.getElementById("textCounter");
      if (textValue.length > 5000 ) {
        element.classList.remove("text-secondary");
        element.classList.add("text-danger");
      } else{
        element.classList.remove("text-danger");
        element.classList.add("text-secondary");
      }
    },

    allowSet({commit},{name}){
      commit("SET_ALLOW_SET", true);
      if (name.includes("*") == true){
        commit("SET_ALLOW_SET", false);
      } 
    },

    //* GPT Actions
    async textCompletion({commit, dispatch, state},{max_tokens, router}){
        commit("SET_SPINNER", true);
        if ((state.textInputGptCount/4) < max_tokens) {
        await UserService.textCompletion(state.textInputGpt, max_tokens)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '110'){
                            commit("SET_SPINNER", false);
                            var cMessage = [true,'Your Credits balance is low! Please click Credits Boosters tab.'];
                          
                            commit("SET_FAILED_SPINNER", cMessage);
                          } else {
                            let resultobj= JSON.parse(response.data.response.result);
                            dispatch("displayOutput", {text: resultobj.choices[0].text});
                            dispatch("getGptRecords", {router: this.$router});
                            commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SPINNER", false);
                        }
                        
                      });
        } else {
          commit("SET_SPINNER", false);
          var maxReached = [true,'Your input Credits are greater than the Max Credits! Increase Max Credits or decrease input Credits.'];
          commit("SET_FAILED_SPINNER", maxReached);
        }
    },
    
    displayOutput({dispatch}, {text}){
      let output = text.replace(/\n/g, "<br>");
      const element = document.getElementById("messagecontainer");
      let children = element.childNodes;
      
      if (children.length > 0){
        for (var i = 0; i < children.length; i++) {
          children[i].removeAttribute('style');
        }
      }

      const para = document.createElement("div");
      para.setAttribute("style", "background-color: #BAF2BB;"); 
      //const node = document.createTextNode(text);
      //para.appendChild(node);
      para.innerHTML = output;
      element.appendChild(para);
      dispatch("scrollDown");
      element.scrollIntoView();
    },

    scrollDown(){
      var textarea = document.getElementById('messagecontainer');
      textarea.scrollTop = 9999999;
    },

    updateInputTextGptCount({commit, dispatch}){
      var textValueGpt = document.getElementById("textinputgpt").value;
      dispatch("updateInputTextGpt", {text: textValueGpt});
      commit("SET_INPUT_TEXT_GPT_COUNT", textValueGpt.length);
    },

    updateInputTextGpt({commit},{text}){
        commit("SET_INPUT_TEXT_GPT", text);
    },

    async getGptRecords({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getGptRecords()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var resStatus = response.data.response.status;
                        if(resStatus == '300' || resStatus == '301' || resStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                        
                      }
                      return [];
                      
                    });
      if ('records' in myJson){
        //map items for tableview:
        const mappedJson = await myJson.records.map(singleObj => {
            var outText = '';
            if(singleObj.outtext.startsWith("\n")){
              var t1 = singleObj.outtext.replace("\n", "");
              outText = t1;
              if(t1.startsWith("\n")){
                var t2 = t1.replace("\n", "");
                outText = t2;
                if(t2.startsWith("\n")){
                  var t3 = t2.replace("\n", "");
                  outText = t3;             
                }             
              }  
            } else {
              outText = singleObj.outtext;
            }
            const rObj = {};
            rObj.intext = singleObj.intext;
            rObj.outtext = outText.replace(/\n/g, "<br>");
            rObj.intokens = singleObj.intokens;
            rObj.outokens = singleObj.outokens; 
            rObj.alltokens = singleObj.alltokens;
            rObj.date = singleObj.date;
            return rObj;
            });
        const uId = myJson.userId;
        var used = myJson.usedtokens.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        var unused = myJson.unusedtokens.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        const credit = [used, unused];
        
        commit("SET_CURRENT_UID", uId);
        commit("SET_GPT_CREDIT", credit);
        commit("SET_GPTRECORDS", mappedJson);
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },

    async generateAudio({commit},{router, text}){
         
        commit("SET_TEXTAREA", text);
        await router.push('/Speechor');
    },

    initializeModalError({commit}) {
      var cMessage = [false,''];
                          
      commit("SET_FAILED_SPINNER", cMessage);
    },

   

    async generatePPTX({commit, dispatch},{router, language, slides, title, description, format, background}){
      commit("SET_PPTXSUCCESS_SPINNER", false);
      commit("SET_PPTXPROCESS_SPINNER", true);

      if (description.length < 6000){
        await UserService.generatePPTX(language, slides, title, description, format, background)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            var expMessage = [true,'Session Expired!'];
                            commit("SET_PPTXFAILED_SPINNER", expMessage);
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                           
                          }else if(respStatus == '117'){
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            commit("SET_PPTXFAILED_SPINNER",[true, response.data.response.message]);
                         
                          }else if(respStatus == '110'){
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            commit("SET_PPTXFAILED_SPINNER", [true, response.data.response.message]);
                           
                          } else if(respStatus == '200'){
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            commit("SET_PPTXSUCCESS_SPINNER", true);
                            dispatch("getPresRecords", {router: this.$router});
                           } else {
                              commit("SET_PPTXPROCESS_SPINNER", false);
                              commit("SET_PPTXFAILED_SPINNER",[true, 'Oops Something went wrong, try again later!']);
                            }
                        } else {
                          commit("SET_PPTXPROCESS_SPINNER", false);
                          commit("SET_PPTXFAILED_SPINNER",[true, 'Oops Something went wrong, try again later!']);
                        }
                        
                        
                    });
      }else {
              commit("SET_PPTXPROCESS_SPINNER", false);
              commit("SET_PPTXFAILED_SPINNER",[true, 'Oops! The description is too long!']);
            }
      
    },



    async getPresRecords({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getPresRecords()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var resStatus = response.data.response.status;
                        if(resStatus == '300' || resStatus == '301' || resStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                        
                      }
                      return [];
                      
                    });
      if ('records' in myJson){
        //map items for tableview:
        const mappedJson = await myJson.records.map(singleObj => {
            const rObj = {};
            rObj.title = singleObj.title;
            rObj.description = singleObj.description;
            rObj.slides = singleObj.slides;
            rObj.tokens = singleObj.tokens; 
            rObj.file = singleObj.file;
            rObj.format = singleObj.format;
            rObj.date = singleObj.date;
            return rObj;
            });
        const uId = myJson.userId;
        var used = myJson.usedtokens.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        var unused = myJson.unusedtokens.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        const credit = [used, unused];
        
        commit("SET_CURRENT_UID", uId);
        commit("SET_PRES_CREDIT", credit);
        commit("SET_PRESRECORDS", mappedJson);
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },

    async downloadPresFile({commit, state},{file}){
        await UserService.downloadPresFile(state.currentUId, file)
        .then(response=> {
          if(response == false){
            commit("SET_PRESDOWNLOADERR", true);
      
          }
        });
    },

    initPresDownloadErr({commit}) {                   
      commit("SET_PRESDOWNLOADERR", false);
    },

    //Imagine Actions

    async variateExternalImage({commit, dispatch},{router, outsize, file}){
      commit("SET_PPTXSUCCESS_SPINNER", false);
      commit("SET_PPTXPROCESS_SPINNER", true);
     
      await UserService.variateExternalImage(outsize, file)
                  .then(response => {
                   
                    
                    if (response.data!=='' && response.data.constructor === Object){
                      var respStatus = response.data.response.status;
                      if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                        commit("SET_PPTXPROCESS_SPINNER", false);
                        var expMessage = [true,'Session Expired!'];
                        commit("SET_PPTXFAILED_SPINNER", expMessage);
                        dispatch("auth/logout");
                        router.push('/login').catch(()=>{});
                       
                      }else if(respStatus == '117'){
                        commit("SET_PPTXPROCESS_SPINNER", false);
                        commit("SET_PPTXFAILED_SPINNER",[true, response.data.response.message]);
                     
                      }
                      else if(respStatus == '120'){
                        //upload file server errors
                        commit("SET_PPTXPROCESS_SPINNER", false);
                        commit("SET_PPTXFAILED_SPINNER",[true, response.data.response.message]);
                     
                      }
                      else if(respStatus == '110'){
                        commit("SET_PPTXPROCESS_SPINNER", false);
                        commit("SET_PPTXFAILED_SPINNER", [true, response.data.response.message]);
                       
                      } else if(respStatus == '200'){
                        commit("SET_PPTXPROCESS_SPINNER", false);
                        commit("SET_PPTXSUCCESS_SPINNER", true);
                        dispatch("getImgRecords", {router: this.$router});
                       } else {
                          commit("SET_PPTXPROCESS_SPINNER", false);
                          commit("SET_PPTXFAILED_SPINNER",[true, 'Oops Something went wrong, try again later!']);
                        }
                    } else {
                      commit("SET_PPTXPROCESS_SPINNER", false);
                      commit("SET_PPTXFAILED_SPINNER",[true, 'Oops Something went wrong, try again later!']);
                    }
                    
                    
                });
     
    },


    async variateImage({state, commit, dispatch},{router, insize, outsize, file}){
      commit("SET_PPTXSUCCESS_SPINNER", false);
      commit("SET_PPTXPROCESS_SPINNER", true);

      if (state.allowedVarImg.includes(insize)){
        await UserService.variateImage(outsize, file)
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var respStatus = response.data.response.status;
                        if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                          commit("SET_PPTXPROCESS_SPINNER", false);
                          var expMessage = [true,'Session Expired!'];
                          commit("SET_PPTXFAILED_SPINNER", expMessage);
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                         
                        }else if(respStatus == '117'){
                          commit("SET_PPTXPROCESS_SPINNER", false);
                          commit("SET_PPTXFAILED_SPINNER",[true, response.data.response.message]);
                       
                        }else if(respStatus == '110'){
                          commit("SET_PPTXPROCESS_SPINNER", false);
                          commit("SET_PPTXFAILED_SPINNER", [true, response.data.response.message]);
                         
                        } else if(respStatus == '200'){
                          commit("SET_PPTXPROCESS_SPINNER", false);
                          commit("SET_PPTXSUCCESS_SPINNER", true);
                          dispatch("getImgRecords", {router: this.$router});
                         } else {
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            commit("SET_PPTXFAILED_SPINNER",[true, 'Oops Something went wrong, try again later!']);
                          }
                      } else {
                        commit("SET_PPTXPROCESS_SPINNER", false);
                        commit("SET_PPTXFAILED_SPINNER",[true, 'Oops Something went wrong, try again later!']);
                      }
                      
                      
                  });
      } else {
              commit("SET_PPTXPROCESS_SPINNER", false);
              commit("SET_PPTXFAILED_SPINNER",[true, 'Oops! The source image is not squared!']);
            }
      
   
      
    },

    async generateImage({commit, dispatch},{router, size, prompt}){
      commit("SET_PPTXSUCCESS_SPINNER", false);
      commit("SET_PPTXPROCESS_SPINNER", true);

      if (prompt.length < 1000){
        await UserService.generateImage(size, prompt)
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            var expMessage = [true,'Session Expired!'];
                            commit("SET_PPTXFAILED_SPINNER", expMessage);
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                           
                          }else if(respStatus == '117'){
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            commit("SET_PPTXFAILED_SPINNER",[true, response.data.response.message]);
                         
                          }else if(respStatus == '110'){
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            commit("SET_PPTXFAILED_SPINNER", [true, response.data.response.message]);
                           
                          } else if(respStatus == '200'){
                            commit("SET_PPTXPROCESS_SPINNER", false);
                            commit("SET_PPTXSUCCESS_SPINNER", true);
                            dispatch("getImgRecords", {router: this.$router});
                           } else {
                              commit("SET_PPTXPROCESS_SPINNER", false);
                              commit("SET_PPTXFAILED_SPINNER",[true, 'Oops Something went wrong, try again later!']);
                            }
                        } else {
                          commit("SET_PPTXPROCESS_SPINNER", false);
                          commit("SET_PPTXFAILED_SPINNER",[true, 'Oops Something went wrong, try again later!']);
                        }
                        
                        
                    });
      }else {
              commit("SET_PPTXPROCESS_SPINNER", false);
              commit("SET_PPTXFAILED_SPINNER",[true, 'Oops! The prompt is too long!']);
            }
      
    },

    async getImgRecords({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getImgRecords()
                    .then(response => {
                      if (response.data!=='' && response.data.constructor === Object){
                        var resStatus = response.data.response.status;
                        if(resStatus == '300' || resStatus == '301' || resStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                        
                      }
                      return [];
                      
                    });
      if ('records' in myJson){
        const mappedJson = await myJson.records.map(singleObj => {
            const rObj = {};
            rObj.prompt = singleObj.prompt;
            rObj.size = singleObj.size;
            rObj.tokens = singleObj.tokens; 
            rObj.file = singleObj.file;
            rObj.format = singleObj.format;
            rObj.date = singleObj.date;
            return rObj;
            });
        const uId = myJson.userId;
        var used = myJson.usedtokens.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        var unused = myJson.unusedtokens.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        const credit = [used, unused];
        
        commit("SET_CURRENT_UID", uId);
        commit("SET_IMG_CREDIT", credit);
        commit("SET_IMG_RECORDS", mappedJson);
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },

    async downloadImgFile({commit, state},{file}){
        await UserService.downloadImgFile(state.currentUId, file)
        .then(response=> {
          if(response == false){
            commit("SET_IMGDOWNLOADERR", true);
          }
        });
    },

    initImgDownloadErr({commit}) {                   
      commit("SET_IMGDOWNLOADERR", false);
    },

    //affiliate program actions

    async joinAffProgram({commit, dispatch},{router}){
        commit("SET_SPINNER", true);
        await UserService.joinAffProgram()
                      .then(response => {
                      
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } else {
                            dispatch("getaffRecords", {router: this.$router});
                            commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_SPINNER", false);
                        }
                        
                      });
    },

    async withdraw({commit, dispatch},{router, affid}){
        commit("SET_SPINNER", true);
        await UserService.withdraw(affid)
                      .then(response => {
                      
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '119'){//withdraw error
                            commit("SET_SPINNER", false);
                            commit("SET_WMESSAGE", ['danger', response.data.response.message]);
                            commit("SET_WDISMISSALERTE", 10);
                              
                          }
                          else {
                            dispatch("getaffRecords", {router: this.$router});
                            commit("SET_WMESSAGE", ['success', response.data.response.result]);
                            commit("SET_WDISMISSALERTE", 10);
                          }
                        } else{
                          commit("SET_SPINNER", false);
                        }
                        
                      });
    },
        
    walertcount({commit},{countdown}){
      commit("SET_WDISMISSALERTE", countdown);
    },

    async getaffRecords({ commit, dispatch},{router}){
      commit("SET_SPINNER", true);
      const myJson = await UserService.getaffRecords()
                    .then(response => {
                 
                      if (response.data!=='' && response.data.constructor === Object){
                        var resStatus = response.data.response.status;
                        if(resStatus == '300' || resStatus == '301' || resStatus == '302'){
                          dispatch("auth/logout");
                          router.push('/login').catch(()=>{});
                          commit("SET_SPINNER", false);
                          return [];
                        } else {
                          return response.data.response.result;
                        }
                        
                      }
                      return [];
                      
                    });
      if ('records' in myJson){
        //map items for tableview:
        const  topFiveJson = await myJson.topfive.map(singleObj => {
            const rObj = {};
            rObj.affid = singleObj.affiliate;
            rObj.rewards = singleObj.rewards;
            rObj.partners = singleObj.partners;
            return rObj;
            });

        const mappedJson = await myJson.records.map(singleObj => {
            const rObj = {};
            rObj.affiliate = singleObj.affiliate;
            rObj.service = singleObj.service;
            rObj.price = singleObj.price;
            rObj.rewarded = singleObj.rewarded; 
            rObj.status = singleObj.status;
            rObj.date = singleObj.date;
            return rObj;
            });

        const uId = myJson.userId;
        var signedup = myJson.signedup;
        var isaffactive = myJson.isaffactive;
        var affId = myJson.affId;
        var balance = myJson.balance;
        var pplemailexist = myJson.pplemailexist;
        var partners = myJson.partners;
        var thismonth = myJson.thismonth;
        var alltime = myJson.alltime;
       
        commit("SET_CURRENT_UID", uId);
        commit("SET_TOPFIVE", topFiveJson);
        commit("SET_AFFRECORDS", mappedJson);

        commit("SET_SIGNEDUP", signedup);
        commit("SET_AFFID", affId);
        commit("SET_ISAFFACTIVE", isaffactive);
        commit("SET_PPLEXIST", pplemailexist);
        commit("SET_AFF_BALANCE", balance);
        commit("SET_PARTNERS", partners);
        commit("SET_AFF_THISM", thismonth);
        commit("SET_AFF_ALLT", alltime);
        
        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }
    },

    async codePayEmail({commit, dispatch},{router}){
        commit("SET_PAYCODESTATE", [false, '','']);
        commit("SET_SPINNER", true);
        await UserService.codePayEmail()
                      .then(response => {
                    
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            commit("SET_PAYCODESTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            commit("SET_PAYCODESENT", true);
                            commit("SET_PAYCODESTATE", [true, response.data.response.result,'success']);
                            commit("SET_SPINNER", false);
                          } else {
                              commit("SET_PAYCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_PAYCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
      
    },
    


    async changePayEmail({commit, dispatch},{payemail, code, router}){
        commit("SET_PAYCODESTATE", [false, '','']);
        commit("SET_SPINNER", true);
        await UserService.changePayEmail(payemail, code)
                      .then(response => {
                      
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            commit("SET_PAYCODESTATE", [true, response.data.response.message,'danger']);
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            commit("SET_PAYCODESENT", false);
                            commit("SET_PAYCODESTATE", [true, response.data.response.result,'success']);
                            dispatch("getpplEmail", {router: this.$router});
                          } else {
                              commit("SET_PAYCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          commit("SET_PAYCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          commit("SET_SPINNER", false);
                        }
                        
                      });
      
    },

    async getSettings({commit, dispatch},{router}){
        commit("SET_SPINNER", true);
        const myJson = await UserService.getSettings()
                      .then(response => {
                       
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            commit("SET_SPINNER", false);
                            return [];
                          }
                          else if (respStatus == '200'){
                            
                            commit("SET_SPINNER", false);
                            return response.data.response.result;
                          } else {
                             
                            commit("SET_SPINNER", false);
                             return [];
                          }
                        } else{
                          commit("SET_SPINNER", false);
                          return [];
                        }
                        
                      });


        if ('accountsRecords' in myJson){
        const accmappedJson = await myJson.accountsRecords.map(singleObj => {
            const rObj = {};
            rObj.accountId = singleObj.accountId;
            rObj.userName = singleObj.userName;
            return rObj;
            });

        const srvmappedJson = await myJson.serversRecords.map(singleObj => {
            const rObj = {};
            rObj.serverId = singleObj.serverId;
            rObj.serverName = singleObj.serverName;
            return rObj;
            });

        const uId = myJson.userId;
        commit("SET_CURRENT_UID", uId);
        commit("SET_PPLEMAIL", myJson.pplRecords);
        commit("SET_ACC_RECORDS", accmappedJson);
        commit("SET_SRV_RECORDS", srvmappedJson);

        commit("SET_SPINNER", false);
      } else{
        commit("SET_SPINNER", false);
      }

        
      
    },

    //Discord Linking

    async unlinkDiscord({commit, dispatch},{itemId, type, router}){
        commit("SET_SPINNER", true);
        await UserService.unlinkDiscord(itemId, type)
                      .then(response => {
                        
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            if (type == 'account'){
                              commit("SET_DISCODESTATE", [true, response.data.response.message,'danger']);
                            } else {
                              commit("SET_SRVDISCODESTATE", [true, response.data.response.message,'danger']);
                            }
                            
                            commit("SET_SPINNER", false);
                           
                          }
                          else if (respStatus == '200'){ 
                            commit("SET_SPINNER", false);
                            dispatch("getSettings", {router: this.$router});
                          } else {
                             
                            commit("SET_SPINNER", false);
                            
                          }
                        } else{
                          commit("SET_SPINNER", false);
                         
                        }
                        
                      });
    },

    async codeDiscordLink({commit, dispatch},{router, item}){
        commit("SET_DISCODESTATE", [false, '','']);
        commit("SET_SRVDISCODESTATE", [false, '','']);
        commit("SET_SPINNER", true);
        await UserService.codeDiscordLink()
                      .then(response => {
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            if(item == 'account'){
                              commit("SET_DISCODESTATE", [true, response.data.response.message,'danger']);
                            } else {
                              commit("SET_SRVDISCODESTATE", [true, response.data.response.message,'danger']);
                            }
                            
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            if(item == 'account'){
                              commit("SET_DISCODESENT", true);
                              commit("SET_DISCODESTATE", [true, response.data.response.result,'success']);
                            } else {
                              commit("SET_SRVDISCODESENT", true);
                              commit("SET_SRVDISCODESTATE", [true, response.data.response.result,'success']);
                            }

                            commit("SET_SPINNER", false);
                          } else {
                              if(item == 'account'){
                                commit("SET_DISCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);   
                              }
                              else {
                                commit("SET_SRVDISCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              }
                              commit("SET_SPINNER", false);
                          }
                        } else{
                          
                          if(item == 'account'){
                            commit("SET_DISCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);   
                          }
                          else {
                            commit("SET_SRVDISCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                          }
                          commit("SET_SPINNER", false);
                        }
                        
                      });
    },
    


    async changeDiscordLink({commit, dispatch},{hash, code, item, router}){
        commit("SET_DISCODESTATE", [false, '','']);
        commit("SET_SPINNER", true);
        await UserService.changeDiscordLink(hash, code, item)
                      .then(response => {
                        
                        if (response.data!=='' && response.data.constructor === Object){
                          var respStatus = response.data.response.status;
                          if(respStatus == '300' || respStatus == '301' || respStatus == '302'){
                            dispatch("auth/logout");
                            router.push('/login').catch(()=>{});
                            commit("SET_SPINNER", false);
                          } 
                          else if(respStatus == '118'){
                            if(item == 'account'){
                              commit("SET_DISCODESTATE", [true, response.data.response.message,'danger']);
                            } else {
                              commit("SET_SRVDISCODESTATE", [true, response.data.response.message,'danger']);
                            }
                            
                            commit("SET_SPINNER", false);
                          }
                          else if (respStatus == '200'){
                            if(item == 'account'){
                              commit("SET_DISCODESENT", false);
                              commit("SET_DISCODESTATE", [true, response.data.response.result,'success']);
                            } else {
                              commit("SET_SRVDISCODESENT", false);
                              commit("SET_SRVDISCODESTATE", [true, response.data.response.result,'success']);
                            }

                            commit("SET_SPINNER", false);
                            dispatch("getSettings", {router: this.$router});
                          } else {
                              if(item == 'account'){
                                commit("SET_DISCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);   
                              }
                              else {
                                commit("SET_SRVDISCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                              }
                              commit("SET_SPINNER", false);
                          }
                        } else{
                            if(item == 'account'){
                                commit("SET_DISCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);   
                            }
                            else {
                              commit("SET_SRVDISCODESTATE", [true,'Oops! Something went wrong, please try again later or contact us.', 'danger']);
                            }
                            commit("SET_SPINNER", false);
                        }
                        
                      });
    },

    cancelDiscordLink({commit}){
      commit("SET_DISCODESTATE", [false, '','']);
      commit("SET_DISCODESENT", false);
    },

    cancelsrvDiscordLink({commit}){
      commit("SET_SRVDISCODESTATE", [false, '','']);
      commit("SET_SRVDISCODESENT", false);
    },

  },
  
  getters: {
    //Speechor Getters
    getBoost(state){
      return state.boostNow;
    },
    getAllowSet(state){
      return state.allowSet;
    },
    getBalance(state){
      return state.currentBalance;
    },
    getProducts(state){
      return state.products;
    },
    getEditorCount(state){
      return state.editorCount;
    },
    getInputText(state){
      return state.textAreaVal;
    },
    getRecordedItems(state){
      return state.records;
    },
    getMusics(state){
      return state.musics;
    },
    getTotalCharacters(state){
      return state.totalCharacters;
    },
    getToolbarShow(state){
      return state.toolBarShow;
    },
    getRate(state){
      return state.resetRate;
    },
    getVolume(state){
      return state.resetVolume;
    },
    getPitch(state){
      return state.resetPitch;
    },
    getAvailableVoices(state){
      return state.availableVoices;
    },
    getTotalActors(state){
      return state.totalActors;
    },
    getLangOptions(state){
      return state.langOptions;
    },
    
    getSpinner(state) {
      return state.showSpinner;
    },

    getSendCodeState(state){
      return state.sendCodeState;
    },
    getResetPassState(state){
      return state.resetPassState;
    },
    // GPT Getters
    getTextInputGpt(state) {
      return state.textInputGpt;
    },
    getTextInputGptCount(state) {
      return state.textInputGptCount;
    },
    getTextOutputGptCount(state) {
      return state.textOutputGptCount;
    },
    getGptRecordedItems(state){
      return state.gptrecords;
    },
    getGptCredit(state){
      return state.currentCredit;
    },
    getGptProducts(state){
      return state.gptproducts;
    },

    //presentor getters
    getPresProducts(state){
      return state.presProducts;
    },
    getPresRecordedItems(state){
      return state.presrecords;
    },
    getPresCredit(state){
      return state.presCredit;
    },

    //Imagine getters
    getImgRecordedItems(state){
      return state.imgrecords;
    },
    getImgCredit(state){
      return state.imgCredit;
    },
    getImgProducts(state){
      return state.imgProducts;
    },

    //aff getters
    getAffRecords(state){
      return state.affrecords;
    },
    getTopFive(state){
      return state.afftopfive;
    },

    getaffsignedup(state){
      return state.affsignedup;
    },
    getaffId(state){
      return state.affId;
    },
    getaffBalance(state){
      return state.affBalance;
    },
    getaffpartners(state){
      return state.affpartners;
    },
    getaffthismonth(state){
      return state.affthismonth;
    },
    getaffalltime(state){
      return state.affalltime;
    },
    getWMessage(state){
      return state.wmessage;
    },

    getdismissCountDown(state){
      return state.dismissCountDown;
    },

    getpaypalCodeState(state){
      return state.paypalCodeState;
    },

    getpaypalCodeSent(state){
      return state.paypalCodeSent;
    },
    getpplemail(state){
      return state.pplemail;
    },
    getpplexist(state){
      return state.pplemailexist;
    },

    getisaffActive(state){
      return state.isaffActive;
    },

    getDiscordId(state){
      return state.discordId;
    },

    getaccDiscord(state){
      return state.accrecords;
    },

    getsrvDiscord(state){
      return state.srvrecords;
    },

    getdiscordCodeState(state){
      return state.discordCodeState;
    },

    getdiscordCodeSent(state){
      return state.discordCodeSent;
    },

    getsrvdiscordCodeState(state){
      return state.srvdiscordCodeState;
    },

    getsrvdiscordCodeSent(state){
      return state.srvdiscordCodeSent;
    },

    getSignUpeState(state){
      return state.signUpState;
    },
    getCountries(state){
      return state.countries;
    },
    getPhoneCode(state){
      return state.phoneCode;
    }
  

  },
  modules: {
    auth
  }
  
});