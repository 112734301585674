<template>
	<b-card header-tag="header" bg-variant="dark" text-variant="light">
      	<template #header>
        	<p class="mb-0 text-secondary">Settings</p>
      	</template>
		<!--card-body-->
		<b-form class="form-lef">
			<b-form-group id="input-pack-gr" class="mt-1" label-for="input-pack">
		        <b-form-select
		        size="sm"
		        id="input-pack"
		        v-model="selectedLang"
		        :options="getLangOptions"
		        v-on:input="getFilteredVoices"
		        required
		        >
		        <template #first>
		        	<b-form-select-option :value="null" disabled>-- Select Language --</b-form-select-option>
		      	</template>
		    	</b-form-select>
		    </b-form-group>
		    <b-form-group id="input-group-tech">
		    	<b-form-radio-group 
		    	id="radio-group-tech" 
		    	v-model="selectedGender"
		    	:options="genderOptions"
		    	v-on:input="getFilteredVoices"
		    	name="some-radios"
		    	button-variant="outline-primary"
		    	size="sm"
		    	buttons 
		    	required
		    	>
		        </b-form-radio-group>
		    </b-form-group>
		    <p v-if="available">Available actors:</p>
		    <b-form-group id="input-group-voice" class="scroll-voices">
		    	<b-form-radio-group
		    	@input="allowSettings"
		    	size="sm"
		    	id="radio-group-voice" 
		    	v-model="selectedActor"
		    	name="radio-sub-component"
		    	buttons
		    	button-variant="outline-primary"
    			stacked 
		    	required
		    	>
		    	<template v-for="option in getAvailableVoices" >
		    	
		    	  <b-form-radio :value="[option.value, option.text]" :key="option.text">
			        {{ option.text }}
			        <b-badge v-b-tooltip.left title="Actor sample"  variant="success" @click="badge(option.value)"><b-icon icon="play-fill" font-scale="1" aria-hidden="true"></b-icon></b-badge>
			      </b-form-radio>
		    	
			    </template>
			    </b-form-radio-group>
		    </b-form-group>
		    <b-button 
		    :disabled="buttonDisabled"
		    class="w-100 mt-2" 
		    size="md"
		    squared 
		    id="submit-1" 
		    variant="warning"
		    @click.prevent="saveSpeech"
		    >Save Speech</b-button>		    
		</b-form>
		<!-- Info modal: audio created succesfully -->
	    <b-modal 
	    id="audioCreatedModal"
	    size="sm"
		button-size="sm"
	    hide-footer
	    hide-header
	    no-close-on-esc
	    no-close-on-backdrop
	    >
	  		<div v-if="savePrcessing" style="text-align: center;">
	      	<p>Audio processing ...</p>
	      	<div class="text-center">
			  <b-spinner variant="primary"></b-spinner>
			</div>
	        </div> 
	        <div v-else-if="saveSuccess" style="text-align: center;">
	      	  <p>Audio created and saved successfully!</p>
	          <b-icon icon="check2-circle" font-scale="3" animation="throb" variant="success"></b-icon>
	          <b-button class="mt-3" block @click="$bvModal.hide('audioCreatedModal')">Close</b-button>
	        </div>
	        <div v-else-if="saveFailed[0]" style="text-align: center;">
	      	  <p>{{saveFailed[1]}}</p>
	          <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="danger"></b-icon>
	          <b-button class="mt-3" block @click="$bvModal.hide('audioCreatedModal')">Close</b-button>
	        </div>
	      
	    </b-modal>
    </b-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data(){
	 	return{
	 		clicksTimer:[],
	 		available:false,
	 		audio: undefined,
	 		playing: false,
	 		optionListen:'',
	 		selectedActor: ['',''],
	 		selectedGender: 'MALE',
	 		selectedLang: null,
	 		genderOptions:[
	 		 		{text: "male", value: "MALE"},
	 		 		{text: "female", value: "FEMALE"}
	 		]	 
		}
	},
	mounted() {
		this.$store.dispatch("initialize");
	},
	computed: {
	    ...mapGetters(["getLangOptions", "getAvailableVoices"]),

	    

	    buttonDisabled(){
	    	
	    	var saveDisabled;
	    	if (this.selectedLang && !this.selectedActor[0]=='' && !this.$store.state.textAreaVal=='')
	    	{
	    		saveDisabled = false;
	    	} 
	    	else 
	    	{
	    		saveDisabled = true;
	    	}
	    	return saveDisabled;
	    },
	    savePrcessing(){
	    	var showThis;
	    	if (this.$store.state.processingSpinner == true) 
	    	{
	    		showThis = true;
	    	} 
	    	else 
	    	{
	    		showThis = false;
	    	}
	    	return showThis;
	    },
	    saveFailed(){
	    	var savefailed;
	    	if (this.$store.state.failedSpinner[0] == true) 
	    	{
	    		savefailed = [true,this.$store.state.failedSpinner[1]] 
	    	} 
	    	else 
	    	{
	    		savefailed = [false,''];
	    	}
	    	return savefailed;
	    },
	    saveSuccess(){
	    	var saveSuccess;
	    	if (this.$store.state.successSpinner == true) 
	    	{
	    		saveSuccess = true;
	    	} 
	    	else 
	    	{
	    		saveSuccess = false;
	    	}
	    	return saveSuccess;
	    }

	},
	methods: {
		isTooFastAction(){
	      var currentClickTime;
	      if(!Date.now) {
	        Date.now = function(){ return new Date().getTime();};
	        currentClickTime = Date.now();
	      }else{
	        currentClickTime = Date.now();
	      }
	      if(this.clicksTimer.length == 0){
	        this.clicksTimer.push(currentClickTime)
	        return false;
	      }
	      else {
	        if (currentClickTime-this.clicksTimer[this.clicksTimer.length-1]<5000){
	          this.clicksTimer.push(currentClickTime);
	          this.clicksTimer.splice(this.clicksTimer.length-2,1);
	          return true;
	        }
	        this.clicksTimer.push(currentClickTime);
	        this.clicksTimer.splice(this.clicksTimer.length-2,1);
	        return false;
	      }  
	    },

		allowSettings(){
	        this.$store.dispatch("allowSet",{name: this.selectedActor[1]});
	    },
	    
  		getFilteredVoices() {
  			this.selectedActor = ['',''];
        	this.$store.dispatch("getVoices", { language: this.selectedLang, gender: this.selectedGender, myJson: this.$store.state.xmyJson });
        	this.available = true;
      	},

      	saveSpeech() {
      		if (this.isTooFastAction()){
      			window.alert("Too many actions, try again later!");
				return;
			}
        	this.$store.dispatch("saveSpeech", { language: this.selectedLang, actor: this.selectedActor[0], router: this.$router});
        	this.$bvModal.show('audioCreatedModal');
      	},
      	badge(val){
      		var fileName = val.replace(/-/g, '');
      		var file = 'https://speechormanager.univerhome.com/uploads/samples/'+fileName+'.mp3';
      		this.audio = new Audio(file);
      		if (this.playing) return;
            this.audio.play().then(() => this.playing = true);
        	this.audio.addEventListener('ended', () => {
  			this.playing = false
			});
      	}
 	}
	
	 
}

</script>

<style scoped>
	.form-lef{
		text-align: left;
	}
	.scroll-voices{
		overflow-x: hidden;
		overflow-y: auto;
		max-height:150px;
	}
</style>