<template>
 <b-container fluid> 
    <b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> AI Presentation <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>
    <widget-pptx></widget-pptx>
    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2">
          <b-row no-gutters>
            <b-col>
                <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Presentation-settings</p>
                  </template>
                  <b-alert v-if="message" variant="danger"  show>{{ message }}</b-alert>
                  <b-alert v-if="messagetitle" variant="danger" size="sm"  show>{{ messagetitle }}</b-alert>
                  <b-alert v-if="messagedescrip" variant="danger" size="sm"  show>{{ messagedescrip }}</b-alert>
                  <b-form-select
                    size="sm"
                    id="preslang"
                    v-model="selectedpresLang"
                    :options="LangOptions"
                    required
                    @input="langinput"
                    >
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select a Language --</b-form-select-option>
                    </template>
                  </b-form-select>
                 <div class="mt-2 mb-2">
                    <b-form-input
                    size="sm"
                    id="prestitle" 
                    type="text"
                    v-model="title"
                    placeholder="Enter your Presentation title..."
                    maxlength ="150"
                    @input="titleCount"
                    required
                    ></b-form-input>
                    <p id="titcounter" class="my-0 text-secondary"><b-icon icon="pencil-square" variant="secondary" font-scale="1" aria-hidden="true"></b-icon> {{titlecount}}/150</p>
                 </div>
                <div>
                  <label for="format" class="text-secondary">Presentation file format: <b>{{format}}</b></label>
                  <b-form-group id="format" v-slot="{ ariaDescribedby }">
                    <b-form-radio class="d-inline-block mr-3" v-model="format" :aria-describedby="ariaDescribedby" name="format-radios" value="pptx">PPTX</b-form-radio>
                    <b-form-radio class="d-inline-block" v-model="format" :aria-describedby="ariaDescribedby" name="format-radios" value="odp">ODP</b-form-radio>
                  </b-form-group>
                </div>

                <div>
                  <label for="background" class="text-secondary">Background Color: <b>{{background}}</b></label>
                  <b-form-group id="background" v-slot="{ ariaDescribedby }">
                    <b-form-radio class="d-inline-block mr-3" v-model="background" :aria-describedby="ariaDescribedby" name="background-radios" value="white">White</b-form-radio>
                    <b-form-radio class="d-inline-block" v-model="background" :aria-describedby="ariaDescribedby" name="background-radios" value="black">Black</b-form-radio>
                  </b-form-group>
                </div>
 
                  <div class="mt-2 mb-2">
                    <b-form-textarea
                      class="border-secondary"
                      id="presdescription"
                      size="sm"
                      placeholder="Enter your topic description..."
                      rows="3"
                      v-model="description"
                      @input="editdesCount"
                      maxlength ="6000"
                      required
                    ></b-form-textarea>
                    <p id="desCounter" class="my-0 text-secondary"><b-icon icon="pencil-square" variant="secondary" font-scale="1" aria-hidden="true"></b-icon> {{count}}/6000</p>
                  </div>
                </b-card>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col xs="6" md="4" class="p-1 pt-1">
                  <label for="numbslides" class="text-secondary">Slides number: <b>{{numbSlides}}</b></label>
                  <b-form-input 
                  id="tokensrange" 
                  type="range"
                  min="3" 
                  max="20"
                  v-model="numbSlides"
                  ></b-form-input>
            </b-col>
            <b-col xs="6" md="8" class="p-1">
              <b-button 
                  class="w-100 mt-2" 
                  size="md"
                  squared 
                  id="submit-1" 
                  variant="warning"
                  @click.prevent="pptxgenerate"
                  >Generate</b-button>
              </b-col>
              
          </b-row>
        </b-col>
      </b-row>
      <b-row class="border-top border-secondary">
        <table-pptx></table-pptx>
      </b-row>

      <!-- Info modal: audio created succesfully -->
      <b-modal 
        id="pptxCreatedModal"
        size="sm"
        button-size="sm"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        >
          <div v-if="pptxPrcessing" style="text-align: center;">
            <p>Presentation is processing ...</p>
            <div class="text-center">
              <b-spinner variant="primary"></b-spinner>
            </div>
          </div> 
          <div v-else-if="pptxsaveSuccess" style="text-align: center;">
              <p>Presentation created and saved successfully!</p>
              <b-icon icon="check2-circle" font-scale="3" animation="throb" variant="success"></b-icon>
              <b-button class="mt-3" block @click="$bvModal.hide('pptxCreatedModal')">Close</b-button>
          </div>
          <div v-else-if="pptxsaveFailed[0]" style="text-align: center;">
              <p>{{pptxsaveFailed[1]}}</p>
              <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="danger"></b-icon>
              <b-button class="mt-3" block @click="$bvModal.hide('pptxCreatedModal')">Close</b-button>
          </div>
          
        </b-modal>
      
 </b-container>
</template>

<script>
 import PPTXWidget from "@/components/Pptxwidgets.vue";
 import PptxTable from "@/components/PptxTable.vue";


export default {
  components: { 
    'widget-pptx': PPTXWidget ,
    'table-pptx': PptxTable
  },

  data() {
    return {
      regex:/[`@#$%^&*=[\]{}"\\|<>~]/,
      message:'',
      messagetitle:'',
      messagedescrip:'',
      numbSlides:3,
      title:'',
      description:'',
      format:"pptx",
      background:"white",
      count: 0,
      clicksTimer:[],
      titlecount:0,
      selectedpresLang:null,
      LangOptions : [
                    {text: "English", value:"English", disabled: false},
                    {text: "Arabic", value:"Arabic", disabled: false},
                    {text: "Spanish", value:"Spanish", disabled: false},
                    {text: "German", value:"German", disabled: false},
                    {text: "French", value:"French", disabled: false},
                    {text: "Indonesian", value:"Indonesian", disabled: false},
                    {text: "Chinese", value:"Chinese", disabled: false},
                    {text: "Czech", value:"Czech", disabled: false},
                    {text: "Danish", value:"Danish", disabled: false},
                    {text: "Greek", value:"Greek", disabled: false},
                    {text: "Finnish", value:"Finnish", disabled: false},
                    {text: "Filipino", value:"Filipino", disabled: false},
                    {text: "Hungarian", value:"Hungarian", disabled: false},
                    {text: "Italian", value:"Italian", disabled: false},
                    {text: "Japanese", value:"Japanese", disabled: false},
                    {text: "Korean", value:"Korean", disabled: false},
                    {text: "Norwegian", value:"Norwegian", disabled: false},
                    {text: "Dutch", value:"Dutch", disabled: false},
                    {text: "Polish", value:"Polish", disabled: false},
                    {text: "Portuguese", value:"Portuguese", disabled: false},
                    {text: "Russian", value:"Russian", disabled: false},
                    {text: "Slovak", value:"Slovak", disabled: false},
                    {text: "Swedish", value:"Swedish", disabled: false},
                    {text: "Turkish", value:"Turkish", disabled: false},
                    {text: "Hindi", value:"Hindi", disabled: false},
                    {text: "Ukrainian", value:"Ukrainian", disabled: false},
                    {text: "Vietnamese", value:"Vietnamese", disabled: false}
                  ],
    };
  },
 
  computed: {

    pptxPrcessing(){
        var pptxshowThis;
        if (this.$store.state.pptxprocessingSpinner == true) 
        {
          pptxshowThis = true;
        } 
        else 
        {
          pptxshowThis = false;
        }
        return pptxshowThis;
      },

      pptxsaveSuccess(){
        var pptxsaveSuccess;
        if (this.$store.state.pptxsuccessSpinner == true) 
        {
          pptxsaveSuccess = true;
        } 
        else 
        {
          pptxsaveSuccess = false;
        }
        return pptxsaveSuccess;
      },

      pptxsaveFailed(){
        var pptxsavefailed;
        if (this.$store.state.pptxfailedSpinner[0] == true) 
        {
          pptxsavefailed = [true,this.$store.state.pptxfailedSpinner[1]] 
        } 
        else 
        {
          pptxsavefailed = [false,''];
        }
        return pptxsavefailed;
      },
 
  },
 
  methods: {
    isTooFastAction(){
      var currentClickTime;
      if(!Date.now) {
        Date.now = function(){ return new Date().getTime();};
        currentClickTime = Date.now();
      }else{
        currentClickTime = Date.now();
      }
      if(this.clicksTimer.length == 0){
        this.clicksTimer.push(currentClickTime)
        return false;
      }
      else {
        if (currentClickTime-this.clicksTimer[this.clicksTimer.length-1]<5000){
          this.clicksTimer.push(currentClickTime);
          this.clicksTimer.splice(this.clicksTimer.length-2,1);
          return true;
        }
        this.clicksTimer.push(currentClickTime);
        this.clicksTimer.splice(this.clicksTimer.length-2,1);
        return false;
      }  
    },

    pptxgenerate(){
      if (this.isTooFastAction()){
            this.message = "Too many actions, try again later!";
            return;
      }
     
      if(this.selectedpresLang!==null && this.title!=='' && this.description!=='' && this.messagedescrip == '' && this.messagetitle==''){
        this.$store.dispatch("generatePPTX", 
        {
          router: this.$router,
          language: this.selectedpresLang, 
          slides: this.numbSlides,
          title: this.title,
          description: this.description, //limit description to 5000characters
          format: this.format,
          background: this.background
        });
        this.$bvModal.show('pptxCreatedModal');

      } else {
        this.message = "All fields are required, and should be filled correctly!";
      }
      
    },//pptxg() end

    langinput(){
      this.message='';
    },

    editdesCount(){
      this.message='';
      var textValue = document.getElementById("presdescription").value;
      if(this.regex.test(textValue)){
        this.messagedescrip = "Description contains disallowed characters: `@#$%^&*=[]{}\"\\|<>~";
      } else {
        this.messagedescrip ='';
      }
      this.count = textValue.length;
      var element = document.getElementById("desCounter");
      if (textValue.length > 5500 ) {
        element.classList.remove("text-secondary");
        element.classList.add("text-danger");
      } else{
        element.classList.remove("text-danger");
        element.classList.add("text-secondary");
      }
    },

    titleCount(){
      this.message='';
      var titleValue = document.getElementById("prestitle").value;
      if(this.regex.test(titleValue)){
        this.messagetitle = "Title contains disallowed characters: `@#$%^&*=[]{}\"\\|<>~";
      } else {
        this.messagetitle ='';
      }
      this.titlecount = titleValue.length;
      var element = document.getElementById("titcounter");
      if (titleValue.length > 120 ) {
        element.classList.remove("text-secondary");
        element.classList.add("text-danger");
      } else{
        element.classList.remove("text-danger");
        element.classList.add("text-secondary");
      }
    },
   
  }    

};

</script>

<style scoped>
  .alert-danger {
    height: 31px;
  }
  .alert {
    padding: 0.4rem 1.25rem;
  }
</style>