<template>
  <div class="w-100 p-3">
    <b-row class="d-flex justify-content-around my-4">
      <b-col lg="4" class="my-1">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-col>
      <b-col sm="5" md="4" class="my-1">
          <b-form-group 
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
      </b-col>
      <b-col sm="7" md="4" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-button v-show="selected.length > 0" size="sm" v-b-tooltip.bottom title="Delete" variant="danger" @click.prevent="showDeleteDilog" class="mr-1"><b-icon icon="trash" variant="light" font-scale="1" aria-hidden="true"></b-icon> Delete {{selected.length}} record(s)</b-button>
      <!-- Main table element -->
      <b-table
        class="text-light"
        show-empty
        striped
        hover
        dark
        fixed
        small
        stacked="md"
        ref="tableRef"
        selectable
        @row-selected="onRowSelected"
        select-mode="multi"
        :items="getRecordedItems"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      > 
        <template #head(selected)="">
          <template v-if="selected.length > 0">
              <span aria-hidden="true" @click.prevent="checkedAll"><b-icon icon="check-square" variant="primary" font-scale="1.3" aria-hidden="true"></b-icon></span>
              <span class="sr-only">unSelect All</span>
            </template>
            <template v-else>
              <span aria-hidden="true" @click.prevent="checkedAll"><b-icon icon="app" variant="light" font-scale="1.3" aria-hidden="true"></b-icon></span>
              <span class="sr-only">Select All</span>
            </template>
        </template>

        <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true"><b-icon icon="check-square" variant="success" font-scale="1.3" aria-hidden="true"></b-icon></span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true"><b-icon icon="app" variant="light" font-scale="1.3" aria-hidden="true"></b-icon></span>
              <span class="sr-only">Not selected</span>
            </template>
        </template>         
      

        <template #cell(actor)="row">
          {{ row.value }}
        </template>
        <template #cell(text)="row">
          <p class="w-100 text-truncate">{{ row.value }}</p>
        </template>

        <template #cell(actions)="row">
          <b-button size="sm" v-b-tooltip.bottom title="Play" variant="primary" @click.prevent="play(row.item, row.index, $event.target)" class="mr-1"><b-icon icon="play-fill" variant="light" font-scale="1" aria-hidden="true"> </b-icon>
           </b-button>
          <b-button size="sm" v-b-tooltip.bottom title="Download" variant="success" @click.prevent="downloadRecord(row.item)" class="mr-1"><b-icon icon="download" variant="light" font-scale="1" aria-hidden="true"> </b-icon>
           </b-button>
          <b-button size="sm" v-b-tooltip.bottom title="Edit text" variant="warning" @click.prevent="copyText(row.item)" class="mr-1"><b-icon icon="textarea-t" variant="light" font-scale="1" aria-hidden="true"> </b-icon>
          </b-button>
        </template>

      </b-table>
  </b-row>
    <!-- Info modal: listen to audio -->
    <b-modal 
    :id="infoModal.id" 
    :title="infoModal.title" 
    @hide="resetInfoModal"
    hide-footer
    >
      <vuetify-audio :file="file" variant="outline-primary"></vuetify-audio>
    </b-modal>
    <!-- /Info modal: listen to audio -->
    <!-- Info modal: delete audio? -->
    <b-modal
      id="deleteConfirm"
      title="Deleting records"
      @ok="deleteRecords()"
      ok-title="Delete"
      size="sm"
      button-size="sm"
      no-close-on-esc 
      no-close-on-backdrop >
        <div style="text-align: center;">
              <p>You are deleting {{selected.length}} record(s), Do You Confirm?</p>
              <b-icon icon="exclamation-triangle" font-scale="2" animation="throb" variant="danger"></b-icon>
        </div>
    </b-modal>
    <!-- /Info modal: delete audio? -->
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
        VuetifyAudio: () => import('vuetify-audio'),
    },
    data() {
      return {
        selected:[],
        file: '',
        fields: [
          { key: 'selected', label: 'Select' },
          { key: 'id', label: 'ID' },
          { key: 'language', label: 'Language' },
          { key: 'actor', label: 'Actor'},
          { key: 'characters', label: 'Characters'},
          { key: 'text', label: 'Text' },
          { key: 'actions', label: 'Actions' },
          { key: 'date', label: 'Date', sortable: true },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [  { value: 10, text: "10  per page" },
                { value: 25, text: "25  per page" },
                { value: 50, text: "50  per page" },
                { value: 100, text: "Show a lot" }
               ],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: ''
        },
        itemId:'',
        userId:''
        
      }
    },
    computed: {
       ...mapGetters(["getRecordedItems"]),
    },

    mounted() {
      this.$store.dispatch("getRecords", {router: this.$router});    
    },
    
    updated(){
      this.totalRows = this.$store.state.records.length;
      this.userId = this.$store.state.currentUId;
    },

    methods: {
      onRowSelected(items) {
        this.selected = items
      },
      checkedAll() {
        let tableRef = this.$refs.tableRef
        if (this.selected.length>0){
          tableRef.clearSelected()
        } else {
          tableRef.selectAllRows()
        }
      },
      checked(rowindex, rowchecked) {
        let tableRef = this.$refs.tableRef
        if (rowchecked === false){
          tableRef.selectRow(rowindex)
        } else {
          tableRef.selectRow(rowindex)
        }
      },
      copyText(item){
        let textArea = document.getElementById("textinput");
        textArea.value = item.text;
        this.$store.dispatch("updateEditorCount");
        textArea.scrollIntoView();
      },
      showDeleteDilog(){
        this.$bvModal.show('deleteConfirm');
      },
      deleteRecords(){
        this.$store.dispatch("deleteRecords", {rds : this.selected, router: this.$router});
      },
      downloadRecord(item){
        this.itemId = item.id;
        this.$store.dispatch("downloadRecord", {rid : this.itemId});
      },
      play(item, index, button) {
        this.itemId = item.id;
        this.userId = this.$store.state.currentUId;
        this.file = 'https://speechormanager.univerhome.com/uploads/'+this.userId+'/'+this.itemId+'.mp3';
        this.infoModal.title = `MP3 File: ${item.id}`;
        this.$root.$emit('bv::show::modal', this.infoModal.id, button);
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }

</script>

<style scoped>
  /*.resize-tb{
    width: 1200px;
  }*/
</style>