<template>
    <b-row no-gutters>
	    <b-col xs="12" md="6" class="p-2">
	      <b-card border-variant="success" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">AVAILABLE</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bar-chart-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="h6" class="text-success numbers">{{ getImgCredit[1] }}</b-card-text>
	      	<b-card-text text-tag="p">Available Credits (<b-link class="" tag="li" to="/ImagineBoost"> Boost</b-link> )</b-card-text>
	      </b-card>
	    </b-col>
	    
	    <b-col xs="12" md="6" class="p-2">
	      <b-card border-variant="danger" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">USED</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="card-text"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="h6" class="text-danger numbers">{{ getImgCredit[0] }}</b-card-text>
	      	<b-card-text text-tag="p">Used Credits</b-card-text>
	      </b-card>
	    </b-col>
	    
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["getImgCredit"])
  },
  mounted() {
  }

};

</script>

<style scoped>
  .widget-css {
  	background: #ddff87;
  	border-radius: 3px;
  }

  .icon-color {
  	color: #BFC9CA;
  	opacity: 0.1;
  }
  header.card-header {
  	padding: 4px;
  }

  div.card-body {
  	padding: 8px;
  }
</style>