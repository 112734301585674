<template>
  <b-container fluid>
      <b-row class=" p-1" style="background-color:#2f2f30;">
        <div class="text-secondary"> AI Text <span class="text-warning">&#8250;</span> {{$route.name}}</div>
      </b-row>
      <widget-gpt></widget-gpt>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2">
          <b-row no-gutters>
            <b-col>
                <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
                  <template #header>
                    <p class="mb-0 text-secondary">Input-Text</p>
                  </template>
                  <!--card-body-->
                  <b-alert v-if="message" variant="danger"  show>{{ message }}</b-alert>
                  <b-form-textarea
                    class="m-1 border-secondary"
                    id="textinputgpt"
                    size="sm"
                    :value="getTextInputGpt"
                    placeholder="Enter your text here..."
                    rows="4"
                    @input="updateCount"
                    :maxlength ="maxTokens*2"
                  ></b-form-textarea>
            
                  <!--/card-body-->
                  <template #footer>
                      <p id="textCounter" class="my-0 text-secondary" :value="getTextInputGptCount"><b-icon icon="pencil-square" variant="secondary" font-scale="1" aria-hidden="true"></b-icon>Credit Counter Input/Output: {{ Math.floor(getTextInputGptCount/4) }} / {{maxTokens-Math.floor(getTextInputGptCount/4)}}</p>
                  </template>
                </b-card>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col xs="6" md="4" class="p-1 pt-1">
                  <label for="tokensrange">Total Max Credits: {{maxTokens}}</label>
                  <b-form-input 
                  id="tokensrange" 
                  type="range"
                  min="256" 
                  max="4000"
                  v-model="maxTokens"
                  ></b-form-input>
            </b-col>
            <b-col xs="6" md="8" class="p-1">
              <b-button 
                  class="w-100 mt-2" 
                  size="md"
                  squared 
                  id="submit-1" 
                  variant="warning"
                  @click.prevent="getCompletion"
                  >Generate</b-button>
              </b-col>
              
          </b-row>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2">
          <b-card header-tag="header" footer-tag="footer" bg-variant="dark" text-variant="light" body-class="pb-0">
            <template #header>
              <p class="mb-0 text-secondary">Output-Text</p>
            </template>
            <!--card-body-->
            <div>
              <div  id="messagecontainer" class="messagecontainer"></div>
            </div>
            <!--/card-body-->
          </b-card> 
        </b-col>
      </b-row>
      <b-row class="border-top border-secondary">
        <gpt-history></gpt-history>
      </b-row>


      <b-modal 
      id="textCreatedModal"
      size="sm"
    button-size="sm"
      hide-footer
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      >
          <div v-if="saveFailed[0]" style="text-align: center;">
            <p>{{saveFailed[1]}}</p>
            <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="danger"></b-icon>
            <b-button class="mt-3" block @click="closeModal">Close</b-button>
          </div>
        
      </b-modal>

  </b-container>
</template>

<script>
import GPTWidget from "@/components/Gptwidgets.vue";
import GPTHistory from "@/components/GptHistory.vue";
import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      maxTokens: '256',
      message:'',
      clicksTimer:[],
    };
  },

  components: { 
    'widget-gpt': GPTWidget ,
    'gpt-history': GPTHistory
  },

 computed: {
      ...mapGetters(["getTextInputGptCount", "getTextInputGpt"]),

      saveFailed(){
        var savefailed;
        if (this.$store.state.failedSpinner[0] == true) 
        { 
          this.$bvModal.show('textCreatedModal');
          savefailed = [true,this.$store.state.failedSpinner[1]] 
        } 
        else 
        {
          savefailed = [false,''];
        }
        return savefailed;
      },
  },
  methods: {
    isTooFastAction(){
      var currentClickTime;
      if(!Date.now) {
        Date.now = function(){ return new Date().getTime();};
        currentClickTime = Date.now();
      }else{
        currentClickTime = Date.now();
      }
      if(this.clicksTimer.length == 0){
        this.clicksTimer.push(currentClickTime)
        return false;
      }
      else {
        if (currentClickTime-this.clicksTimer[this.clicksTimer.length-1]<5000){
          this.clicksTimer.push(currentClickTime);
          this.clicksTimer.splice(this.clicksTimer.length-2,1);
          return true;
        }
        this.clicksTimer.push(currentClickTime);
        this.clicksTimer.splice(this.clicksTimer.length-2,1);
        return false;
      }  
    },

    updateCount(){
      this.message ='';
      this.checktext();
      this.$store.dispatch("updateInputTextGptCount");
    },

    checktext(){
      let textAreaIn = document.getElementById("textinputgpt").value;
      var format = /[`@#$%^&*=[\]{}\\|<>/~]/;
      if(format.test(textAreaIn)){
        this.message = "Error:These characters are not allowed: `@#$%^&*=[]{}\\|<>/~";
      } else {
        this.message ='';
      }
     
    },

    getCompletion() {
          if (this.isTooFastAction()){
            this.message = "Too many actions, try again later!";
            return;
          }
          let textAreaIn = document.getElementById("textinputgpt").value;
          if (textAreaIn !== '' && this.message == ''){
            this.$store.dispatch("textCompletion", {max_tokens : this.maxTokens, router: this.$router});
          } else {
            this.message = 'No text entered or contain issues!';
          }
          
    },

    closeModal() {
      this.$store.dispatch("initializeModalError");
      this.$bvModal.hide('textCreatedModal');
    }
  }

};

</script>

<style scoped>
  .messagecontainer {
    color: #000;
    background-color: #fff;
    border:1px solid #fff;
    border-radius: 5px ; 
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px; 
    height: 150px;
    margin-bottom: 10px;
  }
 
</style>