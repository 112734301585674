<template>
  <b-container fluid>
      <b-row class=" p-1" style="background-color:#2f2f30;">
        <div class="text-secondary"> Text to Speech <span class="text-warning">&#8250;</span> {{$route.name}}</div>
      </b-row>    
      <widget-1></widget-1>
  		<b-row no-gutters>
        <!-- text editor -->
        <b-col xs="12" md="8" class="p-2">
          <text-editor></text-editor>
        </b-col>   
        <!-- /text editor -->
        <!-- setsection -->
        <b-col xs="12" md="4"  class="p-2 text-light">
          <set-section></set-section>
        </b-col>
        <!-- /setsection -->
  		</b-row>
      <!-- Table -->
      <b-row class="border-top border-secondary">
        <table-view></table-view>
      <!-- /Table -->
      </b-row> 
  </b-container>
</template>

<script>
import TextEditor from "@/components/TextEditor.vue";
import SetSection from "@/components/SetSection.vue";
import TableView from "@/components/TableView.vue";
import Widget from "@/components/Widget.vue";

export default {
 
  data() {
    return {
      
    };
  },

  components: { 
  	'text-editor': TextEditor,
  	'set-section': SetSection,
    'table-view': TableView,
    'widget-1': Widget
    
  },


  computed: {

  }

};

</script>

<style scoped>
  
</style>