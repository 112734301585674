<template>
 <div class="custbackgrd">
  <b-navbar id="topheader" toggleable="md" type="dark" variant="transparent">
      <b-navbar-brand  href="https://speechor.com">
        <img src="../assets/logo.png"   height="75" alt="Speechor">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item class = "txt-head" href="https://speechor.com">Home</b-nav-item>
          <b-nav-item class = "txt-head" href="https://speechor.com/Askus">Support</b-nav-item>

        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-3 mb-4">

          <b-card class="bg-dark mb-4">
            <img
              id="profile-img"
              src="../../public/avatar_2x.png"
              class="profile-img-card"
            />
            <b-form>
              <b-form-group>
                <b-form-input
                size="sm"
                placeholder="Username"
                v-model="user.username"
                v-validate="'required'"
                type="text"
                name="username"
                />
                <b-alert v-if="errors.has('username')" variant="danger"  show>Username is required!</b-alert>
              </b-form-group>
              <b-form-group>
                <b-form-input
                size="sm"
                placeholder="Password"
                v-model="user.password"
                v-validate="'required'"
                type="password"
                name="password"
                />
                <b-alert v-if="errors.has('password')" variant="danger"  show>Password is required!</b-alert>
              </b-form-group>
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="loading"
                @click.prevent="handleLogin"
                >
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Login</span>
                </b-button>
              </b-form-group>
              <b-form-group>
                <b-button 
                v-show="notverified"
                size="sm"
                variant="link"
                class="btn-block "
                @click.prevent="verifyEmail"
                >Email Verification</b-button>
                <b-button 
                size="sm"
                variant="link"
                class="btn-block"
                @click.prevent="forgetPAss"
                >Forgot your password?</b-button>
                <b-button 
                size='sm'
                variant="link"
                class="btn-block"
                ><a href="https://speechor.com/Askus" target="_self">Forgot your email?</a>
              </b-button>
              <b-button 
                size="sm"
                class="btn-block bg-secondary"
                @click.prevent="SignUp"
                >SignUp to Speechor</b-button>
              </b-form-group>
              <b-form-group>
                <b-alert v-if="message" variant="danger"  show>{{message}}</b-alert>
              </b-form-group>
            </b-form>
          </b-card>
      </b-col>
    </b-row>
    <div class="spacing-div" style="height: 70px; width: 100%;"></div>
    <footer id="footer-stl" class="bg-dark">
      <b-navbar type="dark" class="nav-stl" > 
        <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
          <b-nav-item  href="https://speechor.com/">Home</b-nav-item>
          <b-nav-item href="https://speechor.com/Askus">Support</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <b-navbar type="dark" class="nav-stl" >
        <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
          <b-nav-item href="https://speechor.com/Privacypolicy">Privacy</b-nav-item>
          <b-nav-item href="https://speechor.com/Cookies">Cookies</b-nav-item>
          <b-nav-item href="https://speechor.com/Terms">Terms</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    <p class="mb-0 p-1 text-secondary text-center txt-foot-b"> &copy; <span>{{year}}</span> by Speechor</p>
    </footer>
  </div>
</template>

<script>

//source: https://bezkoder.com/jwt-vue-vuex-authentication/
import User from '../models/user';
import { mapGetters } from "vuex";

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      notverified: false,
      message: '',
      year:'2024',
    };
  },

  mounted(){
    this.$store.dispatch("initialize");
    this.year = new Date().getFullYear();
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

     ...mapGetters(["getSendCodeState"]),
  },

  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    forgetPAss(){
      this.$router.push('/forgotPassword');
    },
    SignUp(){
      this.$router.push('/Signup');
    },
    verifyEmail(){
      this.$router.push('/VerifyEmail');
    },
    /* eslint-disable */
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        let att = 1;
        let attemp = this.getCookie("loginattempx");
        if(attemp !== ""){ 
          att = parseInt(attemp);
        }

        if (this.user.username && this.user.password && att < 4) {

          grecaptcha.enterprise.ready(async () => {
                const token = await grecaptcha.enterprise.execute('6LcLCS4qAAAAAB8svEDye5p_D-2ZEdo1-11y1kRO', {action: 'SIGNIN'});

                await this.$store.dispatch('auth/login', {blueSky: this.user, retoken: token}).then(
                  () => {
                    this.$router.push('/');
                  },
                  error => {
                    this.loading = false;
                    this.message = error.response.message;
                    if (error.response.status == 108 || error.response.status == 118) {
                      this.setCookie()
                    }

                    if (error.response.status == 121) {
                      this.notverified = true;
                    }
                  }
                );
                
                
          });
          
        } else {
          this.loading = false;
          this.message = "Email or Password is incorrect. Try again after!";
        }
      });
    },
    /* eslint-enable */

    setCookie() {
    const attemps = this.getCookie("loginattempx");
    //set expire time
    const d = new Date();
    d.setTime(d.getTime() + (13 * 60 * 1000));
    let expires = ";expires="+d.toUTCString();

    if(attemps == ""){ 
      document.cookie = "loginattempx=1" + expires + ";path=/login;SameSite=None;secure";
    } else {
      const newattemps = parseInt(attemps)+1
      document.cookie ="loginattempx=" + newattemps + expires + ";path=/login;SameSite=None;secure";
    }
    
    },

    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {

          const fc = c.substring(name.length, c.length);
          return fc;
        }
      }
      return "";
    },


  }
};
</script>

<style scoped>
#nav a.router-link-exact-active {
  color: white;
}

@media not screen and (max-width: 767px) {
  #topheader{
    padding-right: 5%;
    padding-left: 5%;
  }
}

#nav-brand{
  margin-left: 30px;
}

.txt-head{
  display: block;
  font-size: 1em;
  margin-top: 0.45em;
  margin-bottom: 0.45em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 200;
}

#footer-stl{ 
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .nav-stl{
    background-color:#31004b!important;
  }

  .txt-foot-b{
  font-size: 0.8em;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 100;

  }

.custbackgrd {
  background: rgb(110,0,183);
  background: linear-gradient(180deg, rgba(75, 0, 130, 1)0%, rgba(114, 14, 158, 1) 20%, rgba(128, 0, 128, 1)40%,rgba(69, 44, 99, 1)60%,rgba(51, 0, 111, 1)80%);
  margin-left: 0px;
  margin-right: 0px;
  min-height: 100vh;
}


.profile-img-card {
  width: 75px;
  height: 75px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 25%;
  -webkit-border-radius: 25%;
  border-radius: 25%;
}
</style>