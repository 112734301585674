import Vue from "vue";
import VueRouter from "vue-router";
import Speechor from "../views/Speechor.vue";
import MusicLibrary from "../views/MusicLibrary.vue";
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import VerifyEmail from '../views/VerifyEmail.vue';
import AddFunds from '../views/AddFunds.vue';
import Tutorial from '../views/Tutorial.vue';
import Settings from '../views/Settings.vue';
import Gpt from '../views/Gpt.vue';
import Gptboost from '../views/Gptboost.vue';
import Gpttutorial from '../views/Gpttutorial.vue';
import ForgotPass from '../views/LoginForget.vue';
import PassReset from '../views/LoginPassReset.vue';
import Presentor from '../views/Presentor.vue';
import PresBoost from '../views/PresBoost.vue';
import PresTutorial from '../views/PresTutorial.vue';
import AffDashboard from '../views/AffDashboard.vue';
import AffTutorial from '../views/AffTutorial.vue';
import Imagine from '../views/Imagine.vue';
import ImagineBoost from '../views/ImagineBoost.vue';
import ImagineTutorial from '../views/ImagineTutorial.vue';
import SpeechorBot from '../views/SpeechorBot.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: "/Speechor",
    name: "Text to Speech User Interface",
    component: Speechor
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () =>
      //import(/* webpackChunkName: "about" */ "../views/Speechly.vue")
  },
  {
    path: "/",
    name: "home",
    component: Imagine
  },
  {
    path: "/MusicLibrary",
    name: "Text to Speech Music Library",
    component: MusicLibrary
  },
  
  {
    path: '/login',
    component: Login
  },

  {
    path: '/Signup',
    component: Signup
  },

  {
    path: '/VerifyEmail',
    component: VerifyEmail
  },

  {
    path: '/forgotPassword',
    component: ForgotPass
  },

  {
    path: '/PasswordReset',
    component: PassReset
  },

  {
    path: "/AddFunds",
    name: "Text to Speech Blance Boosters",
    component: AddFunds
  },
  {
    path: "/Tutorial",
    name: "Text to Speech Tutorial",
    component: Tutorial
  },
  {
    path: "/Settings",
    name: "Settings",
    component: Settings
  },

  //Gpt routers
  {
    path: "/AIgenerator",
    name: "AI Text User Interface",
    component: Gpt
  },

  {
    path: "/creditsbooster",
    name: "AI Text Blance Boosters",
    component: Gptboost
  },

  {
    path: "/AItexttutorial",
    name: "AI Text Tutorial",
    component: Gpttutorial
  },

  //Presentor routes
  {
    path: "/Presentor",
    name: "AI Presentation User Interface",
    component: Presentor
  },

  {
    path: "/PresBoost",
    name: "AI Presentation Blance Boosters",
    component: PresBoost
  },
  {
    path: "/PresTutorial",
    name: "AI Presentation Tutorial",
    component: PresTutorial
  },

  //Imagine routes
  {
    path: "/Imagine",
    name: "AI Image User Interface",
    component: Imagine
  },

  {
    path: "/ImagineBoost",
    name: "AI Image Blance Boosters",
    component: ImagineBoost
  },
  {
    path: "/ImagineTutorial",
    name: "AI Image Tutorial",
    component: ImagineTutorial
  },

  //Affiliate routes
  {
    path: "/Affiliate-Dashboard",
    name: "Affiliate Dashboard",
    component: AffDashboard
  },
  {
    path: "/Affiliate-Tutorial",
    name: "Affiliate Tutorial",
    component: AffTutorial
  },

  {
    path: "/SpeechorBot",
    name: "SpeechorBot Tutorial",
    component: SpeechorBot
  },


  

  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/Signup', '/forgotPassword', '/PasswordReset', '/Editor', '/VerifyEmail'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('blueSky');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
