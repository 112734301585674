<template>
  <div class="w-100 p-3">
    <b-row class="d-flex justify-content-around my-4">
      <b-col sm="5" md="3" class="my-1">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-col>
      <b-col sm="5" md="3" class="my-1">
          <b-form-group 
            class="mb-0"
          >
            <b-form-select
              v-model="filter"
              id="moodSelect"
              size="sm"
              :options="moodOptions"
            >
              <template #first>
                <b-form-select-option :value="null">-- All moods --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
      </b-col>
      <b-col sm="5" md="3" class="my-1">
          <b-form-group 
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
      </b-col>
      <b-col sm="5" md="3" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <!-- Main table element -->
      <b-table
        class="text-light"
        show-empty
        striped
        fixed
        hover
        dark
        small
        stacked="md"
        :items="getMusics"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template #cell(artist)="row">
          {{ row.value }}
        </template>
        
        <template #cell(play)="row">
          <b-button size="sm" title="Play" variant="primary" @click.prevent="play(row.item, $event.target)" class="mr-1"><b-icon icon="music-player-fill" variant="light" font-scale="1.5" aria-hidden="true"> </b-icon>
           </b-button>
        </template>

      </b-table>
  </b-row>
  <!-- Info modal: listen to audio -->
    <b-modal :id="infoModal.id" :title="infoModal.title" @hide="resetInfoModal" hide-footer>
      <audio controls>
              <source :src="file" type="audio/mpeg">
            </audio>
    </b-modal>
    <!-- /Info modal: listen to audio -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
      return {
        file:'',
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'artist', label: 'Artist'},
          { key: 'mood', label: 'Mood' },
          { key: 'play', label: 'Play/Download'}
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [ 
                { value: 10, text: "10  per page" },
                { value: 25, text: "25  per page" }
               ],
        moodOptions:[
                      { text: 'Bright', value: 'Bright' },
                      { text: 'Calm', value: 'Calm' },
                      { text: 'Drama', value: 'Drama' },
                      { text: 'Funky', value: 'Funky' },
                      { text: 'Happy', value: 'Happy' },
                      { text: 'inspirational', value: 'inspirational' },
                      { text: 'Romantic',value: 'Romantic' },
                      { text: 'Sad', value: 'Sad' }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        infoModal: {
          id: 'info-modal',
          title: ''
        },
        filter: null,
        filterOn: []
      }
    },
    computed: {
      ...mapGetters(["getMusics"]),
    },
    mounted() {
      this.$store.dispatch("getMusics", {router: this.$router}); 
    },
    updated(){
      this.totalRows = this.$store.state.musics.length;
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      play(item, button) {
        this.file = 'https://speechormanager.univerhome.com/uploads/background/'+item.play;
        this.infoModal.title = `MP3 File: ${item.play}`;
        this.$root.$emit('bv::show::modal', this.infoModal.id, button);
      },
      resetInfoModal() {
        this.infoModal.title = ''
      }
    }
  }

</script>

<style scoped>
  /*.resize-tb{
    width: 1200px;
  }*/
  audio{
    max-height:30px;
    min-width: 60px;
    width: 100%;
  }
</style>