<template>
 <div class="custbackgrd">
  <b-navbar id="topheader" toggleable="md" type="dark" variant="transparent">
      <b-navbar-brand  href="https://speechor.com">
        <img src="../assets/logo.png"   height="75" alt="Speechor">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item class = "txt-head" href="https://speechor.com">Home</b-nav-item>
          <b-nav-item class = "txt-head" href="https://speechor.com/Askus">Support</b-nav-item>

        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="12" class="px-3 pt-3 text-secondary text-center">
          <h4>Welcome to Speechor</h4>
         </b-col>
      </b-row>
      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="mb-3">
          <b-card class="bg-dark text-left text-light">
            <b-card-title class="text-center">SignUp</b-card-title>
            <b-alert v-if="message[0]" :variant="message[2]"  show>{{message[1]}}</b-alert>
            <b-alert v-if="getSignUpeState[0]" :variant="getSignUpeState[2]"  show>{{getSignUpeState[1]}}</b-alert>
            <b-alert v-if="typeerr" variant="danger"  show dismissible>{{ typeerr }}</b-alert>

            <b-form data-vv-scope="signupform" v-show="signupformshow">
              <b-form-group
              label="Full name:*"
              description="Enter your legal first-name and last-name exactly as your ID"
              >
                <b-form-input 
                @input="reseterr"
                size="sm"
                v-model="fullname"
                v-validate="'required|max:50'"
                type="text"
                data-vv-as="Full Name"
                name="fullname"
                /> 
              </b-form-group>
  
              <b-form-group
              label="Email:*"
              description="Make sure your email is correct and accessable"
              >
                <b-form-input
                @input="reseterr"
                size="sm"
                v-model="email"
                v-validate="'required|email'"
                type="email"
                data-vv-as="Email"
                name="email"
                /> 
              </b-form-group>
              

              <b-form-group
              label="Country:*"
              description="Select your Country from the list">
                <b-form-select
                    size="sm"
                    id="countries"
                    v-model="selectedCountry"
                    :options="getCountries"
                    v-validate="'required'"
                    data-vv-as="Country"
                    name="country"
                    @input="reseterr"
                    >
                  </b-form-select>
              </b-form-group>

              <b-form-group
              label="Phone:*"
              description="Select your Phone country code then enter your Phone number">
                <b-form-select
                    size="sm"
                    id="countrycode"
                    v-model="selectedCode"
                    :options="getPhoneCode"
                    v-validate="'required'"
                    data-vv-as="Country Code"
                    name="countrycode"
                    @input="reseterr"
                    >
                  </b-form-select>

                   <b-input-group size="sm" :prepend="selectedCode!=='' ? selectedCode:'+1'" class="">
                    <b-form-input
                      id="phone"
                      @input="reseterr"
                      size="sm"
                      v-model="phone"
                      v-validate="'required|numeric|max:12'"
                      data-vv-as="Phone"
                      name="phone"
                      /> 
                  </b-input-group>
              </b-form-group>

              <b-form-group
              label="Password:*"
              description="Enter a secure password">
                <b-form-input
                @input="reseterr"
                size="sm"
                v-validate="'required|min:10|max:16'"
                v-model = "newpassword"
                name="new_password" 
                type="password"
                ref="newpassword"
                data-vv-as="Password" 
                autocomplete="off"
                />
               
              </b-form-group
              >
              <b-form-group

              label="Password Confirmation:*"
              description="Re-enter your password"
              >
                <b-form-input
                @input="reseterr"
                size="sm"
                v-validate="'required|confirmed:newpassword'"
                v-model = "confirmpassword"
                name="password_confirmation" 
                type="password" 
                data-vv-as="Password Confirmation" 
                autocomplete="off"
                />
              </b-form-group>
              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('signupform')">
                <div class="fontsize" v-if="errors.has('signupform.email')">
                  &#10004; {{ errors.first('signupform.email') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.fullname')">
                  &#10004; {{ errors.first('signupform.fullname') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.new_password')">
                  &#10004; {{ errors.first('signupform.new_password') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.password_confirmation')">
                  &#10004; {{ errors.first('signupform.password_confirmation') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.country')">
                  &#10004; {{ errors.first('signupform.country') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.countrycode')">
                  &#10004; {{ errors.first('signupform.countrycode') }}
                </div>
                <div class="fontsize" v-if="errors.has('signupform.phone')">
                  &#10004; {{ errors.first('signupform.phone') }}
                </div>
              </div>
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="signuploading"
                @click.prevent="SignUp"
                >
                  <span v-if="signuploading" class="spinner-border spinner-border-sm"></span>
                  <span>SignUp</span>
                </b-button>
                
              </b-form-group>
            </b-form>

            <b-form data-vv-scope="submitcode" v-show="signcodeformshow">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                size="sm"
                placeholder="Enter verification code (check email)"
                v-model="code"
                v-validate="'required|max:10'"
                type="text"
                data-vv-as="code"
                name="code"
                /> 
              </b-form-group>

              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('submitcode')">
                <div class="fontsize" v-if="errors.has('submitcode.code')">
                  &#10004; {{ errors.first('submitcode.code') }}
                </div>
              </div>
             
              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="submitloading"
                @click.prevent="verifyEmailSignupCode"
                >
                  <span v-if="submitloading" class="spinner-border spinner-border-sm"></span>
                  <span>Submit Code</span>
                </b-button>
              </b-form-group>
            </b-form>
            <b-button 
                size="sm"
                variant="link"
                class="btn-block "
                @click.prevent="signIn"
                >Already Signed-Up? Signin</b-button>
            <b-button 
                size='sm'
                variant="link"
                class="btn-block "
                ><a href="https://speechor.com/Askus">Contact us</a>
            </b-button>
          </b-card>
      </b-col>
    </b-row>
    <div class="spacing-div" style="height: 120px; width: 100%;"></div>
    <footer id="footer-stl" class="bg-dark">
      <b-navbar type="dark" class="nav-stl" > 
        <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
          <b-nav-item  href="https://speechor.com/">Home</b-nav-item>
          <b-nav-item href="https://speechor.com/Askus">Support</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
      <b-navbar type="dark" class="nav-stl" >
        <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
          <b-nav-item href="https://speechor.com/Privacypolicy">Privacy</b-nav-item>
          <b-nav-item href="https://speechor.com/Cookies">Cookies</b-nav-item>
          <b-nav-item href="https://speechor.com/Terms">Terms</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    <p class="mb-0 p-1 text-secondary text-center txt-foot-b"> &copy; <span>{{year}}</span> by Speechor</p>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      //signupformshow:false,
      email: '',
      fullname:'',
      newpassword:'',
      confirmpassword:'',
      typeerr:'',
      signuploading: false,
      submitloading: false,
      code:'',
      message: [false,'',''],
      year:'2024',
      selectedCountry:'',
      phone:'',
      selectedCode:'',
      referrer : '',
     

    };
  },
  mounted(){

    this.$store.dispatch("initialize");
    this.$store.dispatch("signupCountriesSelect");
    this.year = new Date().getFullYear();

    this.referrer = this.$route.query.r;
    if(this.referrer !== undefined && this.referrer !== '' && this.referrer !== null) {
        this.setCookie();
    }
  },
  computed: {

    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    ...mapGetters(["getSignUpeState", "getCountries", "getPhoneCode"]),

    signupformshow(){
        return this.$store.state.signupformshow;     
    },

    signcodeformshow(){
        return this.$store.state.signcodeformshow;     
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    /* eslint-disable */
    SignUp () {
        this.signuploading = true;
        const affCookie = this.getCookie("referid_spchrr");
        this.$validator.validateAll('signupform').then(
          (result) => {
            if (result) {
              grecaptcha.enterprise.ready(async () => {
                const token = await grecaptcha.enterprise.execute('6LcLCS4qAAAAAB8svEDye5p_D-2ZEdo1-11y1kRO', {action: 'SIGNUP'});
                await this.$store.dispatch("SignUp", {fullname: this.fullname, email: this.email, country:this.selectedCountry, phone:this.selectedCode+this.phone, password: this.newpassword, retoken: token, referrer: affCookie, router: this.$router});
                this.signuploading = false;
              });
              
            } else {
              this.message = [true, 'Please fix these errors!', 'danger'];
              this.signuploading = false;
            }
          
        });  
    },  

    verifyEmailSignupCode() {
      this.submitloading = true;
      this.$validator.validateAll('submitcode').then(

      (isValid) => {
        if (isValid) {
          grecaptcha.enterprise.ready(async () => {
                const token = await grecaptcha.enterprise.execute('6LcLCS4qAAAAAB8svEDye5p_D-2ZEdo1-11y1kRO', {action: 'SIGNUPVERIFEMAIL'});
                await this.$store.dispatch('verifyEmailSignupCode', {code: this.code, email:this.email, retoken: token, router: this.$router});

                this.submitloading = false;
          })
           
        } else {
          this.message = [true, 'Please fix these errors!', 'danger'];
          this.submitloading = false;
        }
      }, 
      (error) => {
              this.submitloading = false;
              this.message = [true, error, 'danger'];
      });
    },

    /* eslint-enable */

    reseterr() {
      this.message = [false,'',''];
      this.$store.dispatch('initSignUpState');
    },

    signIn(){
      this.$router.push('/login');

    },

    setCookie() {
      const refcookie = this.getCookie("referid_spchrr");
      //set expire time
      const d = new Date();
      d.setTime(d.getTime() + (14 * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();

      if(refcookie == ""){ 
        document.cookie = "referid_spchrr="+ this.referrer +";" + expires + ";path=/Signup;SameSite=None;secure";
      }
      
    },

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {

            const fc = c.substring(name.length, c.length);
            return fc;
          }
        }
        return "";
    },
  }

};

</script>

<style scoped>

#nav a.router-link-exact-active {
  color: white;
}

@media not screen and (max-width: 767px) {
  #topheader{
    padding-right: 5%;
    padding-left: 5%;
  }
}

#nav-brand{
  margin-left: 30px;
}

.txt-head{
  display: block;
  font-size: 1em;
  margin-top: 0.45em;
  margin-bottom: 0.45em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 200;
}

#footer-stl{ 
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .nav-stl{
    background-color:#31004b!important;
  }

  .txt-foot-b{
  font-size: 0.8em;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 100;

  }
.fontsize{
  font-size: 12px;
}
.custbackgrd {
  background: rgb(110,0,183);
  background: linear-gradient(180deg, rgba(75, 0, 130, 1)0%, rgba(114, 14, 158, 1) 20%, rgba(128, 0, 128, 1)40%,rgba(69, 44, 99, 1)60%,rgba(51, 0, 111, 1)80%);
  margin-left: 0px;
  margin-right: 0px;
  min-height: 100vh;
}
.profile-img-card {
  width: 75px;
  height: 75px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 25%;
  -webkit-border-radius: 25%;
  border-radius: 25%;
}

.page {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 25;
  width: 100%;
  height: 100%;
  text-align: center;
}
.spinner {
  z-index: 26;
  position: relative;
  top: 50%;
}

h6 {
    color: blue;
  }
</style>