<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> AI Image <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>

    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>AI Image Module - Quick start tutorial:</h4>
          </b-col>
      </b-row>

    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h6>What is the AI Image Module?</h6><br>
          <p>This module will help you generate AI images from a prompt (text), also you can generate a variation of an image by uploading one or variate directly from older generations.</p><br><br>

          <h6>How to use the AI Image Module?</h6>
          <p> 
             1) Prompt method:<br>
             Select the prompt option, enter your text describing your image (up to 1000 characters), then select the dimensions of the image from the available list, finally click Generate. The process will take some few seconds, so keep the page opened until finishing the process.<br><br>

             2) Image method:<br>
             Select the Image option, Upload a valid PNG image, size up to 4MB, and it should be square (width = height). Select one of the available output dimensions from the selection list, then click Generate, wait until the process finishes.<br><br>

             3) Speechor Bot method:<br>
             Join our <a href="https://discord.gg/GAXh9yMvJb">Speechor Discord server</a>, talk to Speechor Bot by executing /imagine command followed by your prompt, our Speechor Bot will reply with the AI generated image, also the image is saved locally here in your Speechor AI Image tab. You can check more by visiting our <a href="https://discord.gg/GAXh9yMvJb">Speechor Discord server</a> community, where you can check other users prompts and generate images from their own images with one click.
            
           </p>

           <h6>How to link my Discord account to my Speechor account?</h6>
           <p>First join our <a href="https://discord.gg/GAXh9yMvJb">Speechor Discord server</a>, talk to our Speechor Bot executing the command /subscribe, the bot will reply and DM you a secret code. Copy this code, go here in the settings tab, click Link/Change button under Discord Account section, Paste your code then submit. Once your account linked you can start executing commands requiring subsription like /imagine from our discord server.</p>

           <h6>How to get the image generated?</h6>
           <p>The image details and the download button will be shown in the table. Images are stored in our servers for 2 hours only, make sure to download the image locally in your device direcly after generation. Generated images from our Speechor Discord server will stay on the Discord channel feed where they was generated</p>

           <h6>History table</h6>
           <p>
             - In the history table you will find your last 2 hours generated images with the following details:<br>Prompt, Size, Credits cost ....<br>
             - You can copy the priviously used prompts from the table to the prompt Input-Text area: by clicking "Copy as Input" button.<br>

             - You can use the Actions column buttons to preview, download, or generate another image from this image.
           </p>

           <h6>Balance Status</h6>
           <p>
             At the top of the User Interface you can see your available Credits and your lifetime used Credits. If you want to add credits you can purchase them by clicking "Boost" or click "Balance Boosters" under AI Image tab.
           </p>

           <h6>Cost in Credits by Image size</h6>
           <p>
              512x512 : 36<br>
              1024x1024 : 40<br>
              Standard 1024x1024 : 80<br>
              Standard 1024x1792 : 160<br>
              Standard 1792x1024 : 160<br>
              HD 1024x1024 : 160<br>
              HD 1024x1792 : 240<br>
              HD 1792x1024 :  240<br>
           </p>
           
          </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
 
  data() {
    return {
    };
  },

};

</script>

<style scoped>
  code {
  font-family: Consolas,"courier new";
  color: crimson;
  background-color: #f1f1f1;
  padding: 2px;
  font-size: 105%;
  }

  h6 {
    color: orange;
  }

  P {
    color: white;
  }
</style>