<template>
 <div class="custbackgrd">
  <b-navbar id="topheader" toggleable="md" type="dark" variant="transparent">
      <b-navbar-brand  href="https://speechor.com">
        <img src="../assets/logo.png"   height="75" alt="Speechor">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item class = "txt-head" href="https://speechor.com">Home</b-nav-item>
          <b-nav-item class = "txt-head" href="https://speechor.com/Askus">Support</b-nav-item>

        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
      <b-row class="justify-content-center" no-gutters>
        <b-col xs="12" md="8" class="p-3">
          <b-card class="bg-dark text-center" title="Reset Password">
            <b-card-text>
              Enter a secure new password and confirm it, then click save password.
            </b-card-text>
            <b-alert v-if="getResetPassState[0]" :variant="getResetPassState[2]"  show>{{getResetPassState[1]}}</b-alert>
            <b-alert v-if="typeerr" variant="danger"  show dismissible>{{ typeerr }}</b-alert>
            <b-form data-vv-scope="resetform">
              <b-form-group>
                <b-form-input
                @input="reseterr"
                v-validate="'required|min:10|max:16'"
                v-model = "newpassword"
                name="new_password" 
                type="password" 
                placeholder="Enter New Password" 
                ref="newpassword"
                data-vv-as="New Password" 
                autocomplete="off"
                />
               
              </b-form-group>
              <b-form-group>
                <b-form-input
                @input="reseterr"
                v-validate="'required|confirmed:newpassword'"
                v-model = "confirmpassword"
                name="password_confirmation" 
                type="password" 
                placeholder="Re-enter New Password" 
                data-vv-as="Password Confirmation" 
                autocomplete="off"
                />
              </b-form-group>

              <!-- ERRORS -->
              <div class="alert alert-danger" v-show="errors.any('resetform')">
                <div v-if="errors.has('resetform.new_password')">
                  {{ errors.first('resetform.new_password') }}
                </div>
                <div v-if="errors.has('resetform.password_confirmation')">
                  {{ errors.first('resetform.password_confirmation') }}
                </div>
              </div>

              <b-form-group>
                <b-button 
                variant="primary" 
                size="sm" 
                class="btn-block" 
                :disabled="loading"
                @click.prevent="resetPass"
                >
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Save Password</span>
                </b-button>
                <b-button 
                size='sm'
                variant="link"
                ><a href="https://speechor.com/Askus" target="_blank">Contact us</a></b-button>
              </b-form-group>
             
            </b-form>
          </b-card>
      </b-col>
    </b-row>
    <div class="spacing-div" style="height: 70px; width: 100%;"></div>
    <footer id="footer-stl" class="bg-dark">
        <b-navbar type="dark" class="nav-stl" > 
          <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
            <b-nav-item  href="https://speechor.com/">Home</b-nav-item>
            <b-nav-item href="https://speechor.com/Askus">Support</b-nav-item>
          </b-navbar-nav>
        </b-navbar>
        <b-navbar type="dark" class="nav-stl" >
          <b-navbar-nav class="txt-foot-b ml-auto mr-auto">
            <b-nav-item href="https://speechor.com/Privacypolicy">Privacy</b-nav-item>
            <b-nav-item href="https://speechor.com/Cookies">Cookies</b-nav-item>
            <b-nav-item href="https://speechor.com/Terms">Terms</b-nav-item>
          </b-navbar-nav>
        </b-navbar>
      <p class="mb-0 p-1 text-secondary text-center txt-foot-b"> &copy; <span>{{year}}</span> by Speechor</p>
      </footer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      newpassword:'',
      confirmpassword:'',
      typeerr:'',
      year:'2024',
      loading: false

    };
  },
  mounted(){
    this.$store.dispatch("initialize");
    this.year = new Date().getFullYear();

  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    ...mapGetters(["getResetPassState"]),
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
    if(this.$store.state.verifiedEmail == '' || this.$store.state.verifiedCode == ''){
      this.$router.push('/forgotPassword');
    }
  },
  methods: {
    resetPass () {

      this.$validator.validateAll('resetform').then(
        (result) => {
        if (result) {
          this.$store.dispatch("resetPass", {password: this.newpassword, router: this.$router});  
        } else {
          this.typeerr = 'Please fix these errors!';
        }
        
      });
      
    },

    reseterr() {
      this.typeerr = '';
    },
  }







};

</script>

<style scoped>

#nav a.router-link-exact-active {
  color: white;
}

@media not screen and (max-width: 767px) {
  #topheader{
    padding-right: 5%;
    padding-left: 5%;
  }
}

#nav-brand{
  margin-left: 30px;
}

.txt-head{
  display: block;
  font-size: 1em;
  margin-top: 0.45em;
  margin-bottom: 0.45em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 200;
}

#footer-stl{ 
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .nav-stl{
    background-color:#31004b!important;
  }

  .txt-foot-b{
  font-size: 0.8em;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: 100;

  }

.custbackgrd {
  background: rgb(110,0,183);
  background: linear-gradient(180deg, rgba(75, 0, 130, 1)0%, rgba(114, 14, 158, 1) 20%, rgba(128, 0, 128, 1)40%,rgba(69, 44, 99, 1)60%,rgba(51, 0, 111, 1)80%);
  margin-left: 0px;
  margin-right: 0px;
  min-height: 100vh;
}
.profile-img-card {
  width: 75px;
  height: 75px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 25%;
  -webkit-border-radius: 25%;
  border-radius: 25%;
}
</style>