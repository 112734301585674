<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">
        <div class="text-secondary"> Affiliate Program <span class="text-warning">&#8250;</span> {{$route.name}}</div>
      </b-row>
    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>Affiliate Program - Quick start tutorial:</h4>
          <h6>Introduction:</h6>
          <p>Our legit affiliate program allows you to earn money while using the software. Once you join the program you get your affiliate link, you can use it to invite your friends to join Speehor. Users invited by you will become your partners, you get a commission when they join Speechor and each time they purchase a balance booster.</p>

          <h6>How to join the affiliate program?</h6>
          <p>If you are a current Speechor user, go to the Affiliate Dashboard under the Affiliate Program tab, then click "Create My Invite link". New users will be signup to the affiliate program automatically after purchasing the Speechor Access.</p>

          <h6>How much I earn from each purchase my partners made?</h6>
          <p>You get a 15% commission when your partner purchase the Speechor Access (first purchase). And you get a 10% commission from each partner balance booster purchased. There is no limits you get the commission forever from your partners.</p>

          <h6>How to get My rewards?</h6>
          <p> Approved commissions will be added to your Rewards Blance, you can withdraw them at any time by clicking the "Withdraw" button. Go to settings to add your Affiliate Paypal email address, follow the steps to add or change your Paypal address safely.
           </p>
           
          <h6>How to share the Affiliate link?</h6>
          <p>
            You can share your affiliate link directly from your dashbord by clicking the social media platform icons, or copy the link to share it wherever you want.
          </p>
          
          
          </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
 
  data() {
    return {
    };
  },

};

</script>

<style scoped>
  code {
     font-family: Consolas,"courier new";
  color: crimson;
  background-color: #f1f1f1;
  padding: 2px;
  font-size: 105%;
  }

  h6 {
    color: orange;
  }

  P {
    color: white;
  }
</style>