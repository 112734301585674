//helper function:
//It checks Local Storage for blueSky item.
//If there is a logged in blueSky with Token (JWT), 
//return HTTP Authorization header. Otherwise, return an empty object.

export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('blueSky'));

  if (user && user.typocsscloud) {
    return 'Bearer ' + user.typocsscloud;
  } else {
    return '';
  }
}