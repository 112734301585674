<template>
    <b-row no-gutters>
    	<b-col xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="primary" text-variant="secondary" header-tag="header" class="bg-transparent text-center">
	      	<template #header>
	      		<div class="row">
	      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">Rewards Balance</p>
	      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bar-chart-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-primary numbers">{{getaffBalance}} USD</b-card-text>
	      	<b-button 
                variant="outline-primary" 
                size="sm" 
                class="btn-block" 
                @click.prevent="withdraw"
                :disabled="!getpplexist"
                ><span>Withdraw</span>
          </b-button>
	      </b-card>
	    </b-col>
	    <b-col xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="success" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">This-Month Rewards</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bar-chart-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-success numbers">{{getaffthismonth}}</b-card-text>
	      	<b-card-text text-tag="p" class="mb-0">USD</b-card-text>
	      </b-card>
	    </b-col>
	    
	    <b-col xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="success" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">All-Time Rewards</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="bar-chart-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      		</div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-success numbers">{{getaffalltime}}</b-card-text>
	      	<b-card-text text-tag="p" class="mb-0">USD</b-card-text>
	      </b-card>
	    </b-col>
      	 
	    <b-col xs="12" md="6" lg="3" class="p-2">
	      <b-card border-variant="success" text-variant="secondary" class="bg-transparent text-center" header-tag="header">
	      	<template #header>
	      		<div class="row">
		      		<p class="my-auto pl-2 col-md-8 d-md-inline d-none">Partners</p>
		      		<b-icon class="col-md-4 col-xs-12 icon-color" icon="person-check-fill"  font-scale="2" aria-hidden="true"></b-icon>
	      	    </div>
	      	</template>
	      	<b-card-text text-tag="p" class="text-success">{{getaffpartners}}</b-card-text>
	      	<b-card-text text-tag="p">Active Partners</b-card-text>
	      </b-card>
	    </b-col>
	    
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["getaffBalance","getaffpartners","getaffthismonth", "getaffalltime","getpplexist"])
  },
  mounted() {
      //this.$store.dispatch("getActorsCount", {router: this.$router});
  },
  methods:{
  	withdraw(){
  		this.$store.dispatch("withdraw", {router: this.$router, affid: this.$store.state.affId});
  	}
  }

};

</script>

<style scoped>
  .widget-css {
  	background: #ddff87;
  	border-radius: 3px;
  }

  .icon-color {
  	color: #BFC9CA;
  	opacity: 0.1;
  }
  header.card-header {
  	padding: 4px;
  }

  div.card-body {
  	padding: 8px;
  }
</style>