import axios from 'axios';
import authHeader from './auth-header';
const FormData = require('form-data');
const API_URL = 'https://speechormanager.univerhome.com/api/fth-api/';


class UserService {

  	getVoices() {
    	return axios.post(API_URL,  {
							        "name":"getVoices",
							        "param":{}
							      	},
      								{ headers: {
      											Authorization: authHeader(),
      											'Content-Type': 'application/json' 
      										}
      								});
  	}

    changePass(oldpass,newpass) {
      return axios.post(API_URL,  {
                          "name":"changePass",
                          "param":{
                                  "oldpass" : oldpass,
                                  "newpass" : newpass,
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    changeEmail(oldemail,newemail) {
      return axios.post(API_URL,  {
                          "name":"changeEmail",
                          "param":{
                                  "oldemail" : oldemail,
                                  "newemail" : newemail,
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    saveSpeech(lg,act,tex,textarea) {
      return axios.post(API_URL,  {
                          "name":"saveSpeech",
                          "param":{
                                  "language" : lg,
                                  "actor" : act,
                                  "text" : tex,
                                  "textarea" : textarea
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getRecords() {
      return axios.post(API_URL,  {
                      "name":"getRecords",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getMusics() {
      return axios.post(API_URL,  {
                      "name":"getMusics",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }
    getProducts() {
      return axios.post(API_URL,  {
                      "name":"getProducts",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    deleteRecords(id) {
      return axios.post(API_URL,  {
                          "name":"deleteRecords",
                          "param":{
                                  "recordIds": id
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }
    payment(item) {
      return axios.post(API_URL,  {
                          "name":"payment",
                          "param":{
                                  "product": item
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }
    
    downloadRecord(uid, rid){
      axios({
        url: 'https://speechormanager.univerhome.com/uploads/' + uid + '/' + rid + '.mp3',
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rid+'-speech.mp3');
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }

    // GPT module functions

    textCompletion(prompt, max_tokens) {
      return axios.post(API_URL,  {
                          "name":"textCompletion",
                          "param":{
                                  "prompt": prompt,
                                  "max_tokens": max_tokens
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getGptRecords() {
      return axios.post(API_URL,  {
                      "name":"getGptRecords",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    // Presentor functions

    generatePPTX(language, slides, title, description, format, background) {
      return axios.post(API_URL,  {
                          "name":"generatePPTX",
                          "param":{
                                  "language": language,
                                  "numslides": slides,
                                  "title": title,
                                  "description": description,
                                  "format": format,
                                  "background": background
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getPresRecords() {
      return axios.post(API_URL,  {
                      "name":"getPresRecords",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    downloadPresFile(uid, file){
      return axios({
                url: 'https://speechormanager.univerhome.com/uploads/' + uid + '/pptx/' + file,
                method: 'GET',
                responseType: 'blob'
              })
              .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return true;
              })
              .catch(function (error) {
                  if (error.response || error.request) {
                      return false;
                  } else {
                    return false;
                  }
                });
    }

    //Imagine user service
    variateExternalImage(size, file) {

      const form = new FormData();
      form.append('name', "variateExternalImage");
      form.append('resolution', size);
      form.append('image', file);

      return axios.post(API_URL, 
                        form,
                        { headers: {
                              Authorization: authHeader(),
                              'Content-Type': 'multipart/form-data' 
                          }
                        });
    }


    variateImage(size, file) {
      return axios.post(API_URL,  {
                          "name":"variateImage",
                          "param":{
                                  "image": file,
                                  "resolution": size
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    generateImage(size, prompt) {
      return axios.post(API_URL,  {
                          "name":"generateImage",
                          "param":{
                                  "prompt": prompt,
                                  "resolution": size
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getImgRecords() {
      return axios.post(API_URL,  {
                      "name":"getImgRecords",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    downloadImgFile(uid, file){
      return axios({
                url: 'https://speechormanager.univerhome.com/uploads/' + uid + '/img/' + file,
                method: 'GET',
                responseType: 'blob'
              })
              .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return true;
              })
              .catch(function (error) {
                  if (error.response || error.request) {
                      return false;
                  } else {
                    return false;
                  }
                });
    }
    
    //affiliate program
    joinAffProgram() {
      return axios.post(API_URL,  {
                          "name":"joinAffProgram",
                          "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    withdraw(affid) {
      return axios.post(API_URL,  {
                          "name":"withdraw",
                          "param":{
                            "affid": affid,
                          }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getaffRecords() {
      return axios.post(API_URL,  {
                      "name":"getaffRecords",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    codePayEmail() {
      return axios.post(API_URL,  {
                      "name":"codePayEmail",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    

    changePayEmail(payemail, code) {
      return axios.post(API_URL,  {
                      "name":"changePayEmail",
                      "param":{
                        "payemail": payemail,
                        "code": code,
                      }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    getSettings() {
      return axios.post(API_URL,  {
                      "name":"getSettings",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    //discord linking
    unlinkDiscord(itemId, type) {
      return axios.post(API_URL,  {
                      "name":"unlinkDiscord",
                      "param":{
                        "itemId": itemId,
                        "type": type,
                      }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    codeDiscordLink() {
      return axios.post(API_URL,  {
                      "name":"codeDiscordLink",
                      "param":{}
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    changeDiscordLink(hash, code, item) {
      return axios.post(API_URL,  {
                      "name":"changeDiscordLink",
                      "param":{
                        "hash": hash,
                        "code": code,
                        "item":item
                      }
                      },
                      { headers: {
                            Authorization: authHeader(),
                            'Content-Type': 'application/json' 
                          }
                      });
    }

    
}

export default new UserService();