<template>
  <b-container fluid>
    <b-row class=" p-1" style="background-color:#2f2f30;">
      <div class="text-secondary"> AI Image <span class="text-warning">&#8250;</span> {{$route.name}}</div>
    </b-row>

    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h4>Discord Speechor Bot - Quick start tutorial:</h4>
          </b-col>
      </b-row>

    <b-row class="justify-content-center">
        <b-col xs="12" md="8" class="p-2 text-secondary">
          <h6>What is the Discord Speechor Bot?</h6><br>
          <p>In Discord you can talk to Speechor Bot with commands, if it's already added to the server you are member in. Members talk to the Speechor Bot by using relevant commands like /imagine or /present used to ask Speechor bot to generate AI images and AI presentations inside the discord server. But you need to link your Speechor account with the Discord account first.</p><br><br>

          <h6>How to link my Discord account to my Speechor account?</h6>
           <p>- First join our <a href="https://discord.gg/GAXh9yMvJb">Speechor Discord server</a>, talk to our Speechor Bot executing the command /subscribe, the bot will reply and DM you a secret code.<br>
           - Copy this code, go here in the settings tab, under Discord Accounts, click -Add new account- button,<br>
           - Paste your discord code and the email verification code then submit.<br><br>

            Once your account linked you can start executing commands requiring subsription like /imagine or /present from our discord server or any other server where Speechor Bot is installed including your own servers.</p>

           <h6>How to add Speechor Bot to my own Discord servers?</h6>
          <p>- You can add Speechor Bot to your servers easily by opening the Discord <a href="https://discord.com/application-directory/1264852046008418356">Speechor Bot</a> page  then click -Add App- button.<br>
           - A discord pop up will ask you to select one of your discord servers to install Speechor Bot on, select one. <br>
         - Install Speechor Bot with all the default permissions selected.<br><br>
         IMPORTANT: Ceate text channels where your server members will execute /image and /present commands, the name(s) of the text channel(s) for /image should start with "image_" and the name(s) of the text channel(s) for /present should start with "presentation_", you can create multiple channels. This way, for example the members interested in generating images will use the relevant text channels avoiding being lost in your server, and keeping your server clean and organized, the Speechor Bot will delete random messges inside those channels automatically.<br><br>
          That's it! your servers members are able now to run Speechor Bot commands. Repeat the same process for all your servers</p>

          <h6>What's the benefit of adding Speechor Bot to my server?</h6>
          <p>Adding the Speechor Bot will be an additional feature provided to your server members, this feature will bring more members to join your server and make it more dynamic. Members will spend more time in your server entertaining with the Speechor Bot and interacting with each generated AI images and AI presentations.</p>

          <h6>Make money from Speechor Bot</h6>
          <p>In addition to making your server more dynamic, you can add your server as an affiliate reference, it means that all your server members become affiliated to your Speechor affiliate account. Each time a member from your servers make a payment boosting his Speechor Balances you get a commission from the amount spent. You need just to link your Server to your Speechor affiliate program</p>

          <h6>How to link my Discord server to my Speechor account?</h6>
          <p>First install Speechor bot in your server as explained above. Execute /admin (only the server owner can execute this command), Speechor Bot will reply and send you a long code, copy it. Login to your Speechor account, go to the settings under Discord Servers section, click -add new server- button, past your discord server code, and the email verification code, then Submit. you can add all your discord servers the same way. <br><br>Now each time your servers members spend money on Speechor you get commissions, you can track commissions from your affiliate dashboard</p>
           
          </b-col>
      </b-row>
  </b-container>
</template>

<script>

export default {
 
  data() {
    return {
    };
  },

};

</script>

<style scoped>
  code {
  font-family: Consolas,"courier new";
  color: crimson;
  background-color: #f1f1f1;
  padding: 2px;
  font-size: 105%;
  }

  h6 {
    color: orange;
  }

  P {
    color: white;
  }
</style>