<template>
  <div class="w-100 p-3"> 
    <b-row class="d-flex justify-content-around my-4">
      <b-col lg="4" class="my-1">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search (Last 2 Hours History)"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-col>
      <b-col sm="5" md="4" class="my-1">
          <b-form-group 
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
      </b-col>
      <b-col sm="7" md="4" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <!-- Main table element -->
      <b-table
        class="text-light"
        show-empty
        striped
        dark
        small
        stacked="md"
        ref="tableRef"
        :items="getImgRecordedItems"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      > 
    
        <template #cell(prompt)="row">
          <div class="w-100" v-html="row.value"></div>
          <b-button
          id="descriptioncopy"
          size="sm"
          v-b-tooltip.bottom
          title="Copy to the prompt input" 
          variant="warning" 
          @click.prevent="copyToDescrip(row.item.prompt, 'descriptioncopy')" 
          class="m-1">Copy as Prompt</b-button>
        </template>
        <template #cell(actions)="row">
          <b-button size="sm" v-b-tooltip.bottom title="Preview" variant="success" @click.prevent="previewImage(row.item.file)" class="mr-1 mb-1"><b-icon icon="eye-fill" variant="light" font-scale="1" aria-hidden="true"></b-icon>
           </b-button>
          <b-button size="sm" v-b-tooltip.bottom title="Download" variant="success" @click.prevent="downloadRecord(row.item)" class="mr-1 mb-1"><b-icon icon="download" variant="light" font-scale="1" aria-hidden="true"></b-icon>
           </b-button>

           <b-button size="sm" v-b-tooltip.bottom title="Create Variation 512x512" variant="primary" @click.prevent="variateImage(row.item, '512_512')" class="mr-1 mb-1" :disabled="!allowedVariateImg.includes(row.item.size)"><b-icon icon="brush-fill" variant="light" font-scale="1" aria-hidden="true"> </b-icon>V512x
           </b-button>

           <b-button size="sm" v-b-tooltip.bottom title="Create Variation 1024x1024" variant="primary" @click.prevent="variateImage(row.item, '1024_1024')" class="mr-1 mb-1" :disabled="!allowedVariateImg.includes(row.item.size)"><b-icon icon="brush-fill" variant="light" font-scale="1" aria-hidden="true"> </b-icon>V1024x
           </b-button>

        </template>

      </b-table>
  </b-row>

  <!-- Info modal: audio created succesfully -->
      <b-modal 
        id="imgDownloadMess"
        size="sm"
        button-size="sm"
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        >
          <div v-if="imgdownloaderr" style="text-align: center;">
              <p>File not found! Files older than 2hours was deleted</p>
              <b-icon icon="exclamation-circle" font-scale="3" animation="throb" variant="danger"></b-icon>
              <b-button class="mt-3" block @click.prevent="modalClosed">Close</b-button>
          </div>
          
        </b-modal>

        <b-modal
          id="previewimg"
          title="Image Preview"
          scrollable
          hide-footer
          >
          <b-row class="mb-2">
            <b-col>
              <b-row>
              <div class="ml-2"><b>Small scale preview</b></div>
              </b-row>
            <b-row>
              <div style="text-align: center;">
                <img width="200" :src="imgUrl" alt="NAN">
              </div>
            </b-row>
            </b-col>
            
          </b-row>
          <b-row>
            <b-col>
            <b-row>
              <div class="ml-2"><b>Real scale preview</b></div>
            </b-row>
            <b-row>
              <div style="text-align: center;">
                <img :src="imgUrl" alt="NAN">
              </div>
            </b-row>
            </b-col>
          </b-row>
            
        </b-modal>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
       
    },
    data() {
      return {
        fields: [ 
          { key: 'prompt', label: 'Prompt'},
          { key: 'size', label: 'Size'},
          { key: 'tokens', label: 'Tokens'},
          { key: 'file', label: 'File Name'},
          { key: 'format', label: 'Format'},
          { key: 'actions', label: 'Actions'},
          { key: 'date', label: 'Date', sortable: true },
        ],
        allowedVariateImg: ['256_256', '512_512', '1024_1024', 'st_1024_1024', 'hd_1024_1024'],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [  { value: 10, text: "10  per page" },
                { value: 25, text: "25  per page" },
                { value: 50, text: "50  per page" },
                { value: 100, text: "Show a lot" }
               ],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        userId:'' ,
        file:'',
        format: '',
        imgUrl:'',
       
      }
    },
    computed: {
       ...mapGetters(["getImgRecordedItems"]),

       imgdownloaderr(){
        var imgerr;
        if (this.$store.state.imgdownloadError == true) 
        {
          this.$bvModal.show('imgDownloadMess');
          imgerr = true;
        } 
        else 
        {
          imgerr = false;
        }
        return imgerr;
      },

      
    },

    mounted() {
      this.$store.dispatch("getImgRecords", {router: this.$router});    
    },
    
    updated(){
      this.totalRows = this.$store.state.imgrecords.length;
      this.userId = this.$store.state.currentUId;
    },

    methods: {
      downloadRecord(item){
        this.file = item.file;
        this.format = item.format;
        var fileName = this.file+'.'+this.format;
        this.$store.dispatch("downloadImgFile", {file : fileName});
      },

      variateImage(item, outputSize){
        var inputSize = item.size;
        var fileName = item.file+'.'+item.format;
        this.$store.dispatch("variateImage", {router: this.$router, insize:inputSize, outsize:outputSize, file : fileName});
        this.$bvModal.show('imgCreatedModal');
      },

      copyToDescrip(item, btnid){
        let btnclicked = document.getElementById(btnid);
        btnclicked.removeAttribute("aria-describedby");
        document.activeElement.blur();

        let promptArea = document.getElementById("imgprompt");
        if(promptArea.value.length>0){
          if (window.confirm("There is some text in the text area\n Do you want to delete it?")) {
            promptArea.value = item;
            promptArea.scrollIntoView();
          }
        } else{
          promptArea.value = item;
          promptArea.scrollIntoView();
        }
        
      },

  
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      modalClosed(){
        this.$store.dispatch("initImgDownloadErr");
        this.$bvModal.hide('imgDownloadMess');
      },

      previewImage(item){
        this.imgUrl = 'https://speechormanager.univerhome.com/uploads/' + this.userId + '/img/' + item + '.PNG';

        this.$bvModal.show('previewimg');
      },
    }
  }

</script>

<style scoped>
  /*.resize-tb{
    width: 1200px;
  }*/
</style>