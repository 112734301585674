<template>
    <footer id="footer-stl">
      <p class="mb-0 p-2 text-secondary"> &copy; <span>{{year}}</span> by Speechor</p>
    </footer>
</template>

<script>
export default {
  methods: {
  },
  data() {
    return {
      year:'2021',
    };
  },
  mounted(){
    this.year = new Date().getFullYear();
  }
};
</script>

<style scoped>
  #footer-stl{
    background-color: #2b2b2b;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
</style>