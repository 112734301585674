<template>
  <div class="w-100 p-3"> 
    <b-row class="d-flex justify-content-around my-4">
      <b-col lg="4" class="my-1">
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search (Last 10 Days History)"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
      </b-col>
      <b-col sm="5" md="4" class="my-1">
          <b-form-group 
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
          </b-form-group>
      </b-col>
      <b-col sm="7" md="4" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <!-- Main table element -->
      <b-table
        class="text-light"
        show-empty
        striped
        dark
        small
        stacked="md"
        ref="tableRef"
        :items="getGptRecordedItems"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      > 
    
        <template #cell(intext)="row">
          <div class="w-100">{{ row.value }}</div>
          <b-button
          id="intextcopyspeech"
          size="sm" 
          v-b-tooltip.bottom 
          title="Transform to Speech by Text to Speech tool" 
          variant="success" 
          @click.prevent="generateAudio(row.item.intext, 'intextcopyspeech')" 
          class="m-1">Text to Speech</b-button>
          <b-button 
          id="intextcopyeditor"
          size="sm" 
          v-b-tooltip.bottom 
          title="Copy the text to the input-text editor" 
          variant="warning" 
          @click.prevent="copyToInput(row.item.intext, 'intextcopyeditor')" 
          class="m-1">Copy as Input</b-button>
        </template>
        <template #cell(outtext)="row">
          <div class="w-100" v-html="row.value"></div>
          <b-button 
          id="outextcopyspeech"
          size="sm" 
          v-b-tooltip.bottom 
          title="Transform to Speech by Text to Speech tool" 
          variant="success" 
          @click.prevent="generateAudio(row.item.outtext, 'outextcopyspeech')" 
          class="m-1">Text to Speech</b-button>
          <b-button
          id="outextcopyeditor"
          size="sm"
          v-b-tooltip.bottom
          title="Copy the text to the input-text editor" 
          variant="warning" 
          @click.prevent="copyToInput(row.item.outtext, 'outextcopyeditor')" 
          class="m-1">Copy as Input</b-button>
        </template>

      </b-table>
  </b-row>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
       
    },
    data() {
      return {
        fields: [
          { key: 'intext', label: 'Input' },
          { key: 'outtext', label: 'Output'},
          { key: 'intokens', label: 'Input-Credits'},
          { key: 'outokens', label: 'Output-Credits' },
          { key: 'alltokens', label: 'All-Credits' },
          { key: 'date', label: 'Date', sortable: true },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [  { value: 10, text: "10  per page" },
                { value: 25, text: "25  per page" },
                { value: 50, text: "50  per page" },
                { value: 100, text: "Show a lot" }
               ],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        userId:''  
      }
    },
    computed: {
       ...mapGetters(["getGptRecordedItems"]),
    },

    mounted() {
      this.$store.dispatch("getGptRecords", {router: this.$router});    
    },
    
    updated(){
      this.totalRows = this.$store.state.records.length;
      this.userId = this.$store.state.currentUId;
    },

    methods: {
      copyToInput(item, btnid){
        let btnclicked = document.getElementById(btnid);
        btnclicked.removeAttribute("aria-describedby");
        document.activeElement.blur();

        let textArea = document.getElementById("textinputgpt");
        textArea.value = item.replace(/<br>/g, "\n");
        this.$store.dispatch("updateInputTextGptCount");
        textArea.scrollIntoView();
      },
       
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      generateAudio(item, btnid){
        let btnclicked = document.getElementById(btnid);
        btnclicked.removeAttribute("aria-describedby");
        document.activeElement.blur();
        let textInSpchr = item.replace(/<br>/g, "\n");
        this.$store.dispatch("generateAudio", {text: textInSpchr, router: this.$router});
        window.scrollTo(0, 0);

      }
    }
  }

</script>

<style scoped>
  /*.resize-tb{
    width: 1200px;
  }*/
</style>